







































































import { Event } from "@sportango/backend";
import { applyDurationToDay, applyTimeToDay } from "@sportango/backend";
import { Program } from "@sportango/backend";
import { requiredFieldRule } from "@/utils/validation";
import { EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DialogDatePicker from "../Inputs/DialogDatePicker.vue";
import DialogTimePicker from "../Inputs/DialogTimePicker.vue";
import { AutoCompleteItem } from "../Inputs/mixins";
import MobileAutoComplete from "../Navigation/MobileAutoComplete.vue";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";

@Component({
  name: "make-up-form",
  components: {
    UsersAutoComplete,
    DialogTimePicker,
    DialogDatePicker,
    MobileAutoComplete
  }
})
export default class MakeUpForm extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  EmitClose
) {
  event: Event = {};
  startDate: Date = new Date();
  startTime = "";
  duration = 0;
  isValid = false;
  @Prop({ required: true })
  selectedDate!: CalendarEvent;
  readonly requiredFieldRule = requiredFieldRule;
  readonly options = Program.options;

  get availablePrograms(): Array<AutoCompleteItem> {
    return this.$store.getters.programs.map((p) => {
      return {
        text: p.name || "",
        value: p.id || ""
      };
    });
  }

  get durationValue(): Program.DurationEnum {
    return this.options.duration[this.duration] as Program.DurationEnum;
  }

  get selectedProgram(): Program | undefined {
    return this.$store.getters.programs.find(
      (p) => p.id === this.event.parentItem
    );
  }

  get enableCoaches(): boolean {
    if (this.event.parentItem) {
      this.event.coaches = this.selectedProgram?.coaches;
      return true;
    }
    return false;
  }

  async fetchPrograms(searchString: string) {
    await this.$store.dispatch("searchPrograms", {
      searchString,
      maxResults: 6
    });
  }

  async mounted() {
    await this.fetchPrograms("");
    this.startDate = new Date(Number(this.selectedDate.start));
    const diff =
      Number(this.selectedDate.end) - Number(this.selectedDate.start);
    if (!isNaN(diff)) {
      if (diff < 3600000) {
        this.duration = 0;
      } else if (diff === 3600000 && diff < 5400000) {
        this.duration = 1;
      } else if (diff === 5400000 && diff < 7200000) {
        this.duration = 2;
      } else {
        this.duration = 3;
      }
    }
    this.startTime = dayjs(this.selectedDate.start).format("HH:mm");
  }

  async save(): Promise<void> {
    this.isLoading = true;
    if (this.selectedProgram) {
      const eventStartTime = applyTimeToDay(
        dayjs(this.startDate),
        this.startTime
      );
      const payload: Event = {
        name: `${this.selectedProgram.name} - Makeup`,
        startTime: eventStartTime.toDate(),
        endTime: applyDurationToDay(
          eventStartTime,
          this.durationValue
        ).toDate(),
        ...this.event,
        eventType: "PROGRAM",
        isMakeup: true
      };
      await this.$store.dispatch("createEvent", payload);
      this.close();
    }
    this.isLoading = true;
  }
}
