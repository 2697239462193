











































































import {
  BaseUser,
  Private,
  PrivateEntity,
  PRIVATE_LESSONS_TABLE_NAME
} from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import MobileAutoComplete from "@/components/Navigation/MobileAutoComplete.vue";
import {
  CourtsMixin,
  CurrentUserMixin,
  EmitClose,
  LoadingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { forFirestore } from "@/utils/parser";
import { requiredFieldRule } from "@/utils/validation";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import DialogTimePicker from "@/components/Inputs/DialogTimePicker.vue";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import { addDoc, collection } from "@firebase/firestore";
import { DB } from "@/firebase";
import WarningIcon from "@/assets/icons/saxcons/danger-linear.svg";
import { WatchLoading } from "@/decorators/Loading";
import UserChip from "@/components/common/UserChip.vue";

@Component({
  name: "request-private-lesson",
  components: {
    UserChip,
    UsersAutoComplete,
    MobileAutoComplete,
    DialogDatePicker,
    DialogTimePicker,
    WarningIcon
  }
})
export default class RequestPrivateLesson extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  ResponsiveMixin,
  EmitClose,
  CourtsMixin
) {
  @Prop({ required: false })
  private selectedDate: CalendarEvent | undefined;
  @Prop({ required: false })
  private coachId: string | undefined;
  private lesson: Private;
  private requiredFieldRule = requiredFieldRule;
  private isValid = false;
  private options = Private.options;
  private duration = 0;

  private get durationValue(): Private.DurationEnum {
    return this.options.duration[this.duration] as Private.DurationEnum;
  }

  get coachInfo(): BaseUser | undefined {
    return this.$store.getters.users.find((u) => u.uid === this.coachId);
  }

  constructor() {
    super();
    this.lesson = new PrivateEntity({});
  }

  mounted() {
    this.resetForm();
  }

  @Watch("selectedDate")
  @Watch("coachId")
  @Watch("currentUser")
  resetForm() {
    if (this.selectedDate) {
      this.lesson = new PrivateEntity({
        ...this.lesson,
        startTime: dayjs(new Date(Number(this.selectedDate.start))).format(
          "HH:mm"
        ),
        startDate: new Date(Number(this.selectedDate.start)),
        endDate: new Date(Number(this.selectedDate.end))
      });
      const diff = Number(this.lesson.endDate) - Number(this.lesson.startDate);
      if (!isNaN(diff)) {
        if (diff < 3600000) {
          this.duration = 0;
        } else if (diff === 3600000 && diff < 5400000) {
          this.duration = 1;
        } else if (diff === 5400000 && diff < 7200000) {
          this.duration = 2;
        } else {
          this.duration = 3;
        }
      }
      this.lesson.days = [
        dayjs(this.selectedDate.start).format("ddd") as Private.DaysEnum
      ];
    }
    if (this.coachId) {
      this.lesson.coach = this.coachId;
    }
    // Adding current user to players when requesting
    if (this.currentUser) {
      this.lesson.privatePlayers = [this.currentUser.uid];
    }
    this.lesson.isRequest = true;
    this.lesson.court = ["null"];
    if (
      this.isCurrentUserOnlyCoach &&
      this.$store.getters.featureFlags["coach-players"]
    ) {
      this.lesson.court = ["null"];
    }
  }

  private get cardWidth(): number {
    if (this.isDesktop) {
      return 450;
    } else {
      return innerWidth;
    }
  }

  @WatchLoading()
  async save(): Promise<void> {
    const data = new PrivateEntity({
      ...this.lesson,
      duration: this.durationValue
    });
    // Perform create
    const res = await addDoc(
      collection(DB, PRIVATE_LESSONS_TABLE_NAME),
      forFirestore(data)
    );
    data.id = res.id;
    this.$emit("updated", data);
  }
}
