import { render, staticRenderFns } from "./PrivateLessonCard.vue?vue&type=template&id=e49558a6&"
import script from "./PrivateLessonCard.vue?vue&type=script&lang=ts&"
export * from "./PrivateLessonCard.vue?vue&type=script&lang=ts&"
import style0 from "./PrivateLessonCard.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardText,VCardTitle,VDivider})
