












































































































import { Program } from "@sportango/backend";
import { BaseUser } from "@sportango/backend";
import { CalendarEvent, parseEventsForCalendar } from "@/store/actions/events";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import TrashIcon from "@/assets/icons/saxcons/trash-linear.svg";
import { Event } from "@sportango/backend";
import { CurrentUserMixin, EmitClose, LoadingMixin } from "@/mixins/Helpers";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import { ResponsiveMixin } from "@/mixins/Responsive";

@Component({
  name: "makeup-players",
  components: {
    UsersAutoComplete,
    TickIcon,
    TrashIcon
  }
})
export default class MakeupPlayers extends mixins(
  LoadingMixin,
  EmitClose,
  CurrentUserMixin,
  ResponsiveMixin
) {
  @Prop({ required: true })
  selectedDate!: CalendarEvent;
  @Prop({ required: true })
  existingProgram!: Program;
  newUser = "";

  get requests(): Array<BaseUser | undefined> {
    return (this.selectedDateEvent.requests || []).map((r) =>
      this.$store.getters.users.find((u) => u.uid === r)
    );
  }

  get selectedDateEvent(): CalendarEvent {
    const selectedEvent = parseEventsForCalendar(
      this.$store.getters.events
    ).find((e) => e.id === this.selectedDate.id);
    if (!selectedEvent) {
      throw new Error("Unable to find the event for Makeup");
    } else {
      return selectedEvent;
    }
  }

  get players(): Array<BaseUser | undefined> {
    return (this.selectedDateEvent.players || [])
      .map((p) => this.$store.getters.users.find((u) => u.uid == p.uid))
      .filter((p) => p !== undefined);
  }

  get usersToIgnore(): Array<string> {
    return [
      ...(this.requests || []).map((u) => u?.uid || ""),
      ...(this.players || []).map((u) => u?.uid || "")
    ];
  }

  get usersToConsider(): Array<string> {
    return [
      ...(this.existingProgram.programPlayers || []).map((u) => u.uid || ""),
      ...(this.existingProgram.playerRequests || []).map((u) => u.uid || "")
    ];
  }

  async addPlayer(p: Partial<BaseUser>): Promise<void> {
    this.isLoading = true;
    const payLoad: Partial<Event> = {
      id: this.selectedDateEvent.id,
      players: [
        ...(this.selectedDateEvent.players || []),
        {
          uid: p.uid,
          playerType: "DEFAULT"
        }
      ],
      requests: (this.selectedDateEvent.requests || []).filter(
        (u) => u !== p.uid
      )
    };
    payLoad.playerIds = payLoad.players?.map((p) => p.uid || "");
    await this.$store.dispatch("updateEvent", payLoad);
    this.isLoading = false;
    // this.close();
  }
  async removePlayer(p: BaseUser): Promise<void> {
    this.isLoading = true;
    const payLoad: Partial<Event> = {
      id: this.selectedDateEvent.id,
      requests: (this.selectedDateEvent.requests || []).filter(
        (u) => u !== p.uid
      )
    };
    payLoad.playerIds = payLoad.players?.map((p) => p.uid || "");
    await this.$store.dispatch("updateEvent", payLoad);
    this.isLoading = false;
    this.close();
  }
}
