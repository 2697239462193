



































































































































































import { Private, Program, Event } from "@sportango/backend";
import {
  CurrentUserMixin,
  LoadingMixin,
  PreventBubblingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import {
  EventAction,
  EventActionMenuDisplayOptions,
  EventActionName
} from "./types";
import CalendarEventAction from "./CalendarEventAction.vue";
import ArrowRight from "@/assets/icons/saxcons/arrow-right-linear.svg";
import SportangoOverlay from "@/components/common/Overlay";
import { WatchLoading } from "@/decorators/Loading";

@Component({
  name: "calendar-event-action-list",
  components: {
    CalendarEventAction,
    ArrowRight,
    SportangoOverlay
  }
})
export default class CalendarEventActionList extends mixins(
  ResponsiveMixin,
  CurrentUserMixin,
  LoadingMixin,
  PreventBubblingMixin
) {
  @VModel({ required: true, type: Boolean })
  active!: boolean;
  @Prop({ required: true })
  selectedEvent!: CalendarEvent | undefined;
  @Prop({ required: false, default: () => [] })
  allowedActions!: Array<EventActionName>;
  cancelConfirmation = false;
  pricePending = false;
  private buttonStatus: Record<EventAction["identifier"], boolean> = {
    Reschedule: false,
    Confirm: false,
    Cancel: false,
    View: false,
    "Check-In": false,
    "Reschedule-Current": false,
    Players: false,
    Skip: false,
    Accept: false,
    Request: false
  };

  get actions(): Array<EventAction> {
    let actions: Array<EventAction> = [
      {
        identifier: "View",
        text: "View",
        color: "info"
      }
    ];
    if (this.selectedEvent) {
      if (this.currentUser && this.currentUser.permissions) {
        if (this.selectedEvent.eventType === "PRIVATE_LESSON") {
          if (
            this.selectedPrivateLesson?.coach === this.currentUser.uid &&
            this.selectedEvent.isRequested &&
            !this.selectedEvent.cancelled
          ) {
            actions.push({
              identifier: "Accept",
              text: "Accept",
              color: "success"
            });
          }
          if (
            ((this.currentUser?.permissions.hasCoachAccess &&
              this.selectedEvent.coaches?.includes(this.currentUser.uid)) ||
              (this.selectedEvent.isRequested &&
                this.selectedEvent.players?.find(
                  (p) => p.uid === this.currentUser?.uid
                ))) &&
            !this.selectedEvent.cancelled &&
            !this.selectedEvent.confirmed
          ) {
            actions.push({
              identifier: "Cancel",
              text: "Cancel",
              color: "error"
            });
          }
          if (
            this.currentUser?.permissions.hasCoachAccess &&
            this.selectedEvent.coaches?.includes(this.currentUser.uid)
          ) {
            if (
              !this.selectedEvent.confirmed &&
              !this.selectedEvent.cancelled &&
              !this.selectedEvent.isRequested
            ) {
              actions.push({
                identifier: "Confirm",
                text: "Confirm",
                color: "success"
              });
            }
            if (
              !this.selectedEvent.cancelled &&
              !this.selectedEvent.confirmed
            ) {
              actions.push({
                identifier: "Reschedule-Current",
                text: "Reschedule",
                color: "warning"
              });
            }
          }
          if (this.selectedEvent.players) {
            const currentPlayerEventInfo = this.selectedEvent.players.find(
              (p) => p.uid === this.currentUser?.uid
            );
            if (
              Number(this.selectedEvent.end) > Number(new Date()) &&
              currentPlayerEventInfo
            ) {
              // Current user is part of this event
              actions.push({
                identifier: "Skip",
                text: currentPlayerEventInfo.notAttending
                  ? "Will Attend"
                  : "Won't Attend",
                color: currentPlayerEventInfo.notAttending
                  ? "success"
                  : "warning"
              });
            }
          }
        } else if (this.selectedEvent.eventType === "PROGRAM") {
          // Updated condition
          if (
            this.currentUser?.permissions.hasAdminAccess &&
            !this.selectedEvent.cancelled &&
            dayjs(this.selectedEvent.start).date() === dayjs(new Date()).date()
          ) {
            actions.push({
              identifier: "Cancel",
              text: "Cancel",
              color: "error"
            });
          }
          // Allow checkin is admin or coach and event started, even if cancelled or confirmed
          if (
            !this.selectedEvent.cancelled &&
            ((this.currentUser?.permissions.hasCoachAccess &&
              this.selectedEvent.coaches?.includes(this.currentUser.uid)) ||
              this.currentUser.permissions.hasAdminAccess) &&
            (dayjs(this.selectedEvent.start).format("YYYY-MM-DD") ===
              dayjs().format("YYYY-MM-DD") ||
              Number(this.selectedEvent.start) < Number(new Date()))
          ) {
            actions.push({
              identifier: "Check-In",
              text: "Check In",
              color: "success"
            });
          }
          if (this.selectedEvent.players) {
            const currentPlayerEventInfo = this.selectedEvent.players.find(
              (p) => p.uid === this.currentUser?.uid
            );
            if (
              Number(this.selectedEvent.end) > Number(new Date()) &&
              currentPlayerEventInfo
            ) {
              // Current user is part of this event
              actions.push({
                identifier: "Skip",
                text: currentPlayerEventInfo.notAttending
                  ? "Will Attend"
                  : "Won't Attend",
                color: currentPlayerEventInfo.notAttending
                  ? "success"
                  : "warning"
              });
            }
          }
          if (
            this.selectedEvent.isMakeup &&
            this.currentUser.permissions.hasPlayerAccess &&
            !this.selectedEvent.requests?.includes(this.currentUser.uid) &&
            this.selectedProgram?.programPlayers?.find(
              (p) => p.uid === this.currentUser?.uid
            ) &&
            !this.selectedEvent.players?.find(
              (p) => p.uid === this.currentUser?.uid
            )
          ) {
            actions.push({
              identifier: "Request",
              text: "Join Makeup",
              color: "success"
            });
          }
          if (
            this.selectedEvent.isMakeup &&
            this.currentUser.permissions.hasAdminAccess
          ) {
            actions.push({
              identifier: "Players",
              text: "Makeup Players",
              color: "primary"
            });
          }
          if (this.currentUser.permissions.hasAdminAccess) {
            actions.push({
              identifier: "Players",
              text: "Players",
              color: "primary"
            });
          }
        }
      }
    }
    return actions.filter((a) => {
      if (this.allowedActions && this.allowedActions.length > 0) {
        return this.allowedActions.includes(a.identifier);
      }
      return true;
    });
  }

  get cancelTitle(): string {
    if (this.selectedPrivateLesson) {
      if (this.selectedPrivateLesson?.repeat) {
        return "You're about to cancel a repeating event";
      } else if (this.selectedEvent?.isRequested) {
        if (
          this.selectedEvent.coaches?.find((c) => c === this.currentUser?.uid)
        ) {
          return "Are you sure you want to cancel this Private Lesson Request";
        } else if (
          this.selectedEvent.players?.find(
            (p) => p.uid === this.currentUser?.uid
          )
        ) {
          return "Are you sure you want to cancel your request?";
        } else {
          return "Are you sure you want to cancel this request?";
        }
      } else {
        return "You're about to cancel a private lesson";
      }
    } else if (this.selectedProgram) {
      return "You're about to cancel a Program Event";
    }
    return "You're about to cancel an Event";
  }
  get cancelBody(): string {
    if (this.selectedPrivateLesson) {
      if (this.selectedPrivateLesson?.repeat) {
        return "You're trying to cancel a repeating event. Do you want to cancel only this occurrence or cancel all future occurrences including this.";
      } else if (this.selectedEvent?.isRequested) {
        if (
          this.selectedEvent.coaches?.find((c) => c === this.currentUser?.uid)
        ) {
          return "This will remove the event from both your calendar and the players'.";
        } else if (
          this.selectedEvent.players?.find(
            (p) => p.uid === this.currentUser?.uid
          )
        ) {
          return "The coach may have seen this request and cancelling will remove it from their calendar. Do you want to proceed?";
        } else {
          return "This will convey that this practice/session did not take place. Do you want to proceed?";
        }
      } else {
        return "Doing so will remove this from both of your calendars. Are you sure you want to proceed?";
      }
    } else if (this.selectedProgram) {
      return "";
    }
    return "";
  }

  get cancelButtonText(): string {
    if (this.selectedPrivateLesson?.repeat) {
      return "Only this event";
    } else if (this.selectedEvent?.isRequested) {
      if (
        this.selectedEvent.coaches?.find((c) => c === this.currentUser?.uid)
      ) {
        return "Yes, Remove it";
      } else if (
        this.selectedEvent.players?.find((p) => p.uid === this.currentUser?.uid)
      ) {
        return "Yes, Remove it";
      } else {
        return "Yes, Cancel it";
      }
    } else if (this.selectedProgram) {
      return "Yes, Cancel it";
    } else {
      return "Yes, Remove";
    }
  }
  get pricingNotice(): string {
    if (this.selectedEvent?.players) {
      if (this.selectedEvent.players.length === 1) {
        return "You haven't set up your pricing information yet and we cannot confirm this private lesson. Please head on over to your profile section and add pricing info.";
      } else {
        return "You haven't set up your semi-private pricing information yet and we cannot confirm this private lesson, since it contains more than one player. Please head on over to your profile section and add semi-private pricing info.";
      }
    } else {
      return "Something went wrong with getting your pricing info. We're checking it now";
    }
  }

  get isEventDay(): boolean {
    if (this.selectedEvent) {
      const boundingRect = document
        .getElementById(this.selectedEvent.id || "")
        ?.getBoundingClientRect();
      if (boundingRect) {
        return this.$parent.$el.clientWidth - boundingRect.width < 400;
      }
    }
    return false;
  }

  get eventDisplay(): EventActionMenuDisplayOptions {
    console.log(this.active);
    if (this.selectedEvent) {
      const boundingRect = document
        .getElementById(this.selectedEvent.id || "")
        ?.getBoundingClientRect();
      if (boundingRect) {
        const res: EventActionMenuDisplayOptions = {
          top: `${boundingRect.y}px`,
          left: "",
          width: "200px",
          direction: "right",
          bottom: "unset"
        };
        // Calculate direction
        if (this.isEventDay) {
          res.width = `${boundingRect.width - 50}px`;
          res.left = `${boundingRect.x}px`;
          if (boundingRect.y < window.innerHeight / 2) {
            res.direction = "bottom";
            res.top = `${boundingRect.y + boundingRect.height}px`;
          } else {
            res.direction = "top";
            res.top = "unset";
            res.bottom = `${window.innerHeight - boundingRect.y}px`;
          }
        } else {
          if (
            boundingRect.left + boundingRect.width + 300 <
            window.innerWidth
          ) {
            res.direction = "right";
            res.left = `${boundingRect.x + boundingRect.width}px`;
          } else {
            res.direction = "left";
            res.left = `${boundingRect.x - 300}px`;
          }
          // Add section to change vertical direction as well
          if (boundingRect.y < window.innerHeight / 2) {
            res.direction = `top-${res.direction}`;
            // res.top = Number(res.top.split("px")[0]) - 51 * this.actions.length;
          } else {
            res.direction = `bottom-${res.direction}`;
            res.top = `${
              Number(res.top.split("px")[0]) - 51 * this.actions.length
            }px`;
          }
        }
        return res;
      }
    }
    return {
      top: "0px",
      left: "0px",
      width: "0px",
      direction: "right",
      bottom: "100vh"
    };
  }

  get selectedPrivateLesson(): Private | null {
    return (
      this.$store.getters.privateLessons.find(
        (p) => this.selectedEvent?.parentItem === p.id
      ) || null
    );
  }

  get selectedProgram(): Program | null {
    return (
      this.$store.getters.programs.find(
        (p) => this.selectedEvent?.parentItem === p.id
      ) || null
    );
  }

  async performAction(action: EventAction) {
    if (this.selectedEvent) {
      this.buttonStatus[action.identifier] = true;
      if (action.identifier === "View") {
        this.$emit("view");
      }
      if (action.identifier === "Cancel") {
        this.cancelConfirmation = true;
      }
      if (action.identifier === "Confirm") {
        await this.confirmEvent();
      }
      if (action.identifier === "Reschedule") {
        this.$emit("reschedule");
      }
      if (action.identifier === "Reschedule-Current") {
        this.$emit("reschedule-current");
      }
      if (action.identifier === "Check-In") {
        this.$emit("checkIn");
      }
      if (action.identifier === "Players") {
        this.$emit("players");
      }
      if (action.identifier === "Skip") {
        const newEvent: Partial<Event> = {
          id: this.selectedEvent.id,
          players: (this.selectedEvent.players || []).map((p) => {
            if (p.uid === this.currentUser?.uid) {
              p.notAttending = !p.notAttending;
            }
            return p;
          })
        };
        // Update event information
        await this.$store.dispatch("updateEvent", newEvent);
      }
      if (action.identifier === "Accept") {
        const newEvent: Partial<Event> = {
          id: this.selectedEvent.id,
          isRequest: false
        };
        await this.$store.dispatch("updateEvent", newEvent);
      }
      if (action.identifier === "Request") {
        this.$store.dispatch("updateEvent", {
          id: this.selectedEvent.id,
          requests: [
            ...(this.selectedEvent.requests || []),
            this.currentUser?.uid
          ]
        });
        this.active = false;
      }
      this.buttonStatus[action.identifier] = false;
    }
  }

  @WatchLoading()
  async cancelEvent(cancelAll = false) {
    if (this.selectedEvent) {
      this.buttonStatus.Cancel = true;
      if (cancelAll) {
        if (this.selectedEvent.eventType === "PROGRAM") {
          const payload: Partial<Program> = {
            id: this.selectedEvent.parentItem,
            endDate: dayjs(new Date(Number(this.selectedEvent.end)))
              .add(1, "d")
              .toDate()
          };
          this.$store.dispatch("updateProgram", payload);
        } else if (this.selectedEvent.eventType === "PRIVATE_LESSON") {
          const payload: Partial<Private> = {
            id: this.selectedEvent.parentItem,
            endDate: dayjs(new Date(Number(this.selectedEvent.start))).toDate()
          };
          this.$store.dispatch("updatePrivateLesson", payload);
        }
      }
      await this.$store.dispatch("deleteEvent", this.selectedEvent?.id);
      this.buttonStatus.Cancel = false;
    }
    this.cancelConfirmation = false;
    this.active = false;
  }

  @WatchLoading()
  async confirmEvent() {
    let priceToUse = 0;
    if (!this.currentUser?.additionalInfo?.hourlyPrivatePrice) {
      this.pricePending = true;
      return;
    } else if (
      !this.currentUser.additionalInfo.semiPrivatePrice &&
      this.selectedEvent?.players &&
      this.selectedEvent.players.length > 1
    ) {
      this.pricePending = true;
      return;
    }
    this.buttonStatus.Confirm = true;
    if (
      !this.selectedEvent?.players ||
      this.selectedEvent.players.length === 0
    ) {
      this.pricePending = true;
    } else {
      if (this.selectedEvent.players.length === 1) {
        priceToUse = this.currentUser.additionalInfo.hourlyPrivatePrice || 0;
      } else {
        priceToUse =
          (this.currentUser.additionalInfo.semiPrivatePrice || 0) / 2;
      }
    }
    const payload: Partial<Event> = {
      id: this.selectedEvent?.id,
      confirmed: true,
      paymentProcessed: false,
      price: Number(
        (
          (Number(priceToUse) *
            dayjs(this.selectedEvent?.end).diff(
              this.selectedEvent?.start,
              "minute"
            )) /
          60
        ).toFixed(2)
      ),
      players: this.selectedEvent?.players?.map((p) => {
        if (p.uid) {
          const player = this.$store.getters.users.find((e) => e.uid === p.uid);
          if (
            player &&
            this.currentUser &&
            player.additionalInfo &&
            player.additionalInfo.coachPricing
          ) {
            p.individualPrice = Number(
              (
                (Number(
                  player.additionalInfo?.coachPricing[this.currentUser.uid]
                ) *
                  dayjs(this.selectedEvent?.end).diff(
                    this.selectedEvent?.start,
                    "minute"
                  )) /
                60
              ).toFixed(2)
            );
          }
        }
        return p;
      })
    };
    await this.$store.dispatch("updateEvent", payload);
    this.buttonStatus.Confirm = false;
    this.active = false;
  }

  get sheetTitle(): string {
    return dayjs(this.selectedEvent?.start).format("dddd, MMM D");
  }

  get sheetTime(): string {
    return `${dayjs(this.selectedEvent?.start).format("h:mm a")} - ${dayjs(
      this.selectedEvent?.end
    ).format("h:mm a")}`;
  }
}
