

























































































































































































































































import ProgramsAutoComplete from "@/components/Inputs/ProgramsAutoComplete.vue";
import SportangoWeekPicker from "@/components/Inputs/SportangoWeekPicker.vue";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import CashPaymentButton from "@/components/Payments/CashPaymentButton.vue";
import DropInMobilePaymentItem from "@/components/Payments/DropInMobilePaymentItem.vue";
import EditableAmount from "@/components/Payments/EditableAmount.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import { WatchLoading } from "@/decorators/Loading";
import { DB } from "@/firebase";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import {
  SharedPaymentIntents,
  SharedPaymentIntentsWithPaymentMethod,
  SharedPaymentMethods
} from "@/mixins/Payments";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { ShopPaymentViewItem, StripBaseProduct } from "@/types/Shop";
import { Header } from "@/types/Table";
import {
  getCorrectStatusAndMessage,
  getRightConvenienceFee,
  getRightPaymentMethod,
  parsePaymentDate
} from "@/utils/payments";
import {
  collection,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  where
} from "@firebase/firestore";
import { Unsubscribe } from "@firebase/util";
import {
  BaseUser,
  Event,
  StripeMerchantInfo,
  Transaction,
  TransactionCustomer,
  TRANSACTIONS_TABLE_NAME
} from "@sportango/backend";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { BaseStripeInfo } from "@/mixins/Stripe";
import { getUserById } from "@/store/actions/users";
import weekday from "dayjs/plugin/weekday";
import dayJs from "dayjs";
dayJs.extend(weekday);

@Component({
  name: "run-drop-in-payments",
  components: {
    DropInMobilePaymentItem,
    ProgramsAutoComplete,
    UsersAutoComplete,
    SportangoWeekPicker,
    PaymentStatus,
    CashPaymentButton,
    TablePaymentMethod,
    EditableAmount
  }
})
export default class RunDropInPayments extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  SharedPaymentIntents,
  SharedPaymentMethods,
  ResponsiveMixin,
  BaseStripeInfo,
  SharedPaymentIntentsWithPaymentMethod
) {
  shopDialog = false;
  isLoading = true;
  selectedPlayers: Array<ShopPaymentViewItem> = [];
  amountEditors: Record<string, number> = {};
  eventSubscription: Unsubscribe | null = null;
  transactionSubscription: Unsubscribe | null = null;
  startDate: Date = new Date();

  selectedProgram = "";
  selectedPlayer = "";

  selectedShopPlayer = "";
  selectedShopPlayerCustomerId: string | undefined = "";
  selectedProducts: Array<string> = [];
  stripProducts: StripBaseProduct[] = [];
  runPaymentLoader = false;
  markAsPaidLoader = false;

  @Watch("startDate")
  onStartDateChanged() {
    this.selectedProgram = "";
  }

  get headers(): Array<Header<ShopPaymentViewItem>> {
    return [
      {
        value: "customerName",
        text: "Player"
      },
      /*{
        value: "programName",
        text: "Program",
        sortable: false
      },
      {
        value: "lessonDate",
        text: "Program Date",
        sortable: true,
        width: "140px"
      },*/
      {
        value: "description",
        text: "Description"
      },
      {
        value: "amount",
        text: "Amount",
        align: "center",
        sortable: true,
        width: "150px"
      },
      {
        value: "convenienceFee",
        text: "Fee",
        sortable: false,
        align: "center"
      },
      {
        value: "total",
        text: "Total",
        sortable: false,
        align: "center"
      },
      {
        value: "status",
        text: "Status",
        align: "center",
        sortable: false
      },
      {
        value: "paymentMethod",
        text: "Method",
        sortable: false,
        align: "center",
        width: "100px"
      },
      {
        value: "paidInCash",
        text: "Cash",
        align: "center",
        sortable: false,
        width: "100px"
      },
      {
        value: "paymentDateShort",
        text: "Payment Date",
        align: "center",
        sortable: true
      }
    ];
  }

  get items(): Array<ShopPaymentViewItem> {
    return this.$store.getters.transactions.map((t) => {
      const transactionCustomer = t.customers as TransactionCustomer[];
      const amount = transactionCustomer[0].amount as number;
      const convenienceFee = transactionCustomer[0].convenienceFee as number;
      const total = (amount as number) + convenienceFee;

      const description = transactionCustomer
        .map((tc) => tc.description)
        .join(",");

      const user = this.$store.getters.users.find((u) => {
        if (t.customerIds) {
          return u.uid == t.customerIds[0];
        }
      });

      const paymentIntent = this.paymentIntents_.find(
        (pI) => pI.id === transactionCustomer[0].paymentIntentId
      );
      const { status, statusMessage } = getCorrectStatusAndMessage(
        transactionCustomer[0],
        paymentIntent
      );
      const { short, full } = parsePaymentDate(
        paymentIntent?.created,
        transactionCustomer[0]
      );
      const paymentMethod =
        this.paymentMethods_.find(
          (pM) => pM.id === getRightPaymentMethod(paymentIntent || null)
        ) || null;
      const defaultPaymentMethod =
        this.defaultPaymentMethods.find(
          (d) => d.customer === transactionCustomer[0].id
        ) || null;

      const tableItem: ShopPaymentViewItem = {
        customerName: user?.displayName || user?.email || "",
        //amount: this.centsToUsd(amount).usd,
        amount: amount,
        convenienceFee,
        // total: this.centsToUsd(total).usd,
        total: total,
        status,
        statusMessage,
        description,
        paymentMethod: paymentMethod || defaultPaymentMethod || null,
        paymentDateShort: short,
        paymentDate: full,
        uid: transactionCustomer[0].uid as string,
        disabled: (() => {
          if (
            transactionCustomer[0].id &&
            (defaultPaymentMethod || paymentMethod)
          ) {
            if (
              status === "failed" ||
              status === "notStarted" ||
              status === "cancelled"
            ) {
              return false;
            }
          }
          if (this.isLoading) {
            return true;
          }
          return true;
        })(),
        stripeCustomerId: transactionCustomer[0].id,
        isPaymentRunning: (() => {
          if (transactionCustomer[0]) {
            if (transactionCustomer[0].paidInCash) {
              return false;
            }
            if (paymentIntent === undefined) {
              switch (transactionCustomer[0].status) {
                case "success":
                case "failed":
                case "missingStripeCustomerId":
                case "missingPaymentMethod":
                case "cancelled":
                case "requiresAttention":
                case "notStarted":
                  return false;
                case "processing":
                  return true;
                default:
                  return false;
              }
            }
          }
          return false;
        })(),
        date: paymentIntent?.created || 0,
        defaultPaymentMethod: defaultPaymentMethod,
        itemId: `${t.id}`,
        paidInCash: transactionCustomer[0].paidInCash,
        transaction: t
      };
      return tableItem;
    });
  }

  get allowedSelectedPlayers(): ShopPaymentViewItem[] {
    return this.selectedPlayers.filter((s) => !s.disabled);
  }

  get eventTransactionMap(): Record<string, Transaction> {
    const results: Record<string, Transaction> = {};
    this.$store.getters.events.forEach((e) => {
      if (e.id) {
        if (!results[e.id]) {
          const eventTransaction = this.$store.getters.transactions.find(
            (t) => t.parentItem === e.id
          );
          if (eventTransaction) {
            results[e.id] = eventTransaction;
          }
        }
      }
    });
    return results;
  }

  get eventPlayersMap(): Record<string, BaseUser[]> {
    const results: Record<string, BaseUser[]> = {};
    this.$store.getters.events.forEach((e) => {
      if (e.id) {
        if (!results[e.id]) {
          const eventPlayers = this.$store.getters.users.filter((u) =>
            e.playerIds?.includes(u.uid)
          );
          if (eventPlayers.length > 0) {
            results[e.id] = eventPlayers;
          }
        }
      }
    });
    return results;
  }

  get showRunButton(): boolean {
    return (
      this.items.filter((i) => !i?.disabled && !i?.isPaymentRunning).length > 0
    );
  }

  get eventPlayers(): Array<BaseUser & { event: Event }> {
    const results: Array<BaseUser & { event: Event }> = [];

    this.$store.getters.events.forEach((e) => {
      e.players?.forEach((p) => {
        const playerInfo = this.$store.getters.users.find(
          (u) => u.uid === p.uid
        );
        if (
          e.players?.find((p) => p.uid === playerInfo?.uid)?.playerType !==
          "DROP_IN"
        ) {
          return;
        }
        if (playerInfo && p.hasAttended) {
          if (this.playerToFilter) {
            if (playerInfo.uid !== this.playerToFilter) {
              return;
            }
          }
          results.push({
            ...playerInfo,
            event: e
          });
        }
      });
    });
    return results;
  }

  get merchantInfo(): StripeMerchantInfo | undefined {
    return this.$store.getters.merchantInfo;
  }

  get programToFilter(): string | null {
    if (this.selectedProgram && this.selectedProgram.length > 0) {
      return this.selectedProgram;
    }
    return null;
  }

  get playerToFilter(): string | null {
    if (this.currentUser?.permissions.hasAdminAccess) {
      if (this.selectedPlayer && this.selectedPlayer.length > 0) {
        return this.selectedPlayer;
      }
    } else if (this.currentUser?.permissions.hasPlayerAccess) {
      return this.currentUser.uid;
    }
    return null;
  }

  get noDataMessage(): string {
    if (this.programToFilter) {
      return "No Programs found";
    } else {
      return "Please select a player";
    }
  }

  changeAmount(itemId: string, amount: number) {
    if (this.amountEditors[itemId]) {
      delete this.amountEditors[itemId];
    }
    this.amountEditors = {
      ...this.amountEditors,
      [itemId]: amount
    };
    this.amountEditors[itemId] = amount;
  }

  calculateAmountForPlayer(
    event: Event,
    transactionCustomer?: TransactionCustomer
  ): number {
    if (transactionCustomer?.amount) {
      return transactionCustomer.amount;
    }
    const program = this.$store.getters.programs.find(
      (p) => p.id === event.parentItem
    );
    return program?.lessonPrice || 0;
  }

  changeCashStatus_(uid: string, item: ShopPaymentViewItem) {
    const newItem = JSON.parse(JSON.stringify(item));
    const newCustomers = newItem.transaction?.customers?.map(
      (c: TransactionCustomer) => {
        return {
          ...c,
          paidInCash: !c.paidInCash,
          cashPaidDate: Number(new Date())
        };
      }
    );
    delete newItem.transaction.createdAt;
    if (newItem.transaction?.customers) {
      newItem.transaction.customers = newCustomers;
    }
    // console.log(newItem.transaction);
    this.$store.dispatch("updateTransaction", newItem.transaction);
  }

  runPayment_() {
    this.allowedSelectedPlayers.forEach((item: ShopPaymentViewItem) => {
      const newItem = JSON.parse(JSON.stringify(item));
      const newCustomers = newItem.transaction?.customers?.map(
        (c: TransactionCustomer) => {
          return {
            ...c,
            retry: false,
            paidInCash: !c.paidInCash,
            cashPaidDate: Number(new Date())
          };
        }
      );
      delete newItem.transaction.createdAt;
      if (newItem.transaction?.customers) {
        newItem.transaction.customers = newCustomers;
      }
      this.$store.dispatch("updateTransaction", newItem.transaction);
    });
  }

  async mounted() {
    this.startTransactionWatch();
    this.$store.commit("transactions", []);
    // await this.getProducts();
  }

  @Watch("merchantInfo")
  @Watch("startDate")
  @Watch("playerToFilter")
  startTransactionWatch() {
    if (this.transactionSubscription) {
      this.transactionSubscription();
    }
    const startOfWeek = dayJs(this.startDate).weekday(1);
    const endOfWeek = dayJs(this.startDate).weekday(7);

    const queries: QueryConstraint[] = [
      where("transactionType", "==", "shop"),
      where("createdAt", ">=", dayJs(startOfWeek).toDate()),
      where("createdAt", "<=", dayJs(endOfWeek).toDate())
    ];
    if (this.selectedPlayer) {
      queries.push(where("customerIds", "array-contains", this.selectedPlayer));
    }
    this.transactionSubscription = onSnapshot(
      query(collection(DB, TRANSACTIONS_TABLE_NAME), ...queries),
      this.watchTransaction
    );
  }

  @WatchLoading()
  async watchTransaction({ docs }: QuerySnapshot) {
    const transactions = docs.map((t) => {
      return {
        ...t.data(),
        id: t.id,
        loadingState: true
      };
    });
    this.$store.commit("transactions", transactions);

    this.paymentIntentsLoaded_ = false;
    this.paymentIntentIdsLength = 0;
    this.paymentIntents_ = [];
    this.paymentMethods_ = [];
    if (this.$store.getters.transactions.length > 0) {
      this.getPaymentIntentsWithPaymentMethods(
        this.$store.getters.transactions
      );
    }
  }

  /** Unsubscribe from onSnapshot watching event paid */
  beforeDestroy() {
    if (this.transactionSubscription) {
      this.transactionSubscription();
    }
  }

  async getProducts() {
    const data = await this.$stripeClient?.get("/products/oneTime");
    if (data) {
      this.stripProducts = data.data;
    }
  }
  productsFilter(item: StripBaseProduct, queryText: string) {
    const textOne = item.name.toLowerCase();
    const searchText = queryText.toLowerCase();
    return textOne.indexOf(searchText) > -1;
  }
  removeSelectedProducts(item: StripBaseProduct) {
    const index: number = this.selectedProducts.indexOf(item.id);
    if (index >= 0) this.selectedProducts.splice(index, 1);
  }
  get productTotalPrice(): {
    usdFormatted: string;
    usd: number;
    cents: number;
  } {
    let total = 0;
    total = this.stripProducts
      .filter((p) => this.selectedProducts.includes(p.id))
      .map((p) => p.default_price.unit_amount)
      .reduce((a, b) => a + b, 0);
    return this.centsToUsd(total);
  }

  get productDescription(): string {
    return this.stripProducts
      .filter((p) => this.selectedProducts.includes(p.id))
      .map((p) => p.name)
      .join(", ");
  }

  get shopButtonStatus() {
    return this.selectedShopPlayer == "" || this.selectedProducts.length == 0
      ? "disable"
      : "enable";
  }

  generateTransactionCustomers(tType: string): TransactionCustomer[] {
    const defaultPaymentMethod =
      this.defaultPaymentMethods.find(
        (d) => d.customer === this.selectedShopPlayerCustomerId
      ) || null;
    const customer: TransactionCustomer[] = [
      {
        id: this.selectedShopPlayerCustomerId,
        uid: this.selectedShopPlayer,
        amount: this.productTotalPrice.usd,
        convenienceFee: getRightConvenienceFee(
          "processing",
          this.productTotalPrice.usd,
          defaultPaymentMethod,
          {},
          this.$store.getters.merchantInfo
        ),
        paidInCash: tType === "cash",
        cashPaidDate: Number(dayjs(new Date())),
        description: this.productDescription,
        status: "processing"
      }
    ];
    if (tType === "cash") {
      delete customer[0].status;
      return customer;
    } else {
      return customer;
    }
  }

  async shopRunPayment() {
    const transactionPayload: Transaction = {
      merchantId: this.$store.getters?.merchantInfo?.merchantId,
      customerIds: [this.selectedShopPlayer],
      customers: this.generateTransactionCustomers("online"),
      transactionType: "shop",
      createdAt: new Date()
    };
    try {
      this.runPaymentLoader = true;
      await this.$store.dispatch("createTransaction", transactionPayload);
      this.runPaymentLoader = false;
      this.shopDialog = false;
    } catch (e) {
      console.log(`Wops! something went wrong!`);
    }
  }
  async shopMarkAsPaid() {
    const transactionPayload: Transaction = {
      merchantId: this.$store.getters?.merchantInfo?.merchantId,
      customerIds: [this.selectedShopPlayer],
      customers: this.generateTransactionCustomers("cash"),
      transactionType: "shop",
      createdAt: dayjs(new Date()).toDate()
    };
    try {
      this.markAsPaidLoader = true;
      await this.$store.dispatch("createTransaction", transactionPayload);
      this.markAsPaidLoader = false;
      this.shopDialog = false;
    } catch (e) {
      console.log(`Wops! something went wrong!`);
    }
  }

  @Watch("shopDialog")
  async onShopDialogChanged(newVal: boolean) {
    this.selectedShopPlayer = "";
    this.selectedProducts = [];
    newVal && (await this.getProducts());
  }

  @Watch("selectedShopPlayer")
  onSelectedShopPlayerChanged(id: string) {
    this.findUser(id);
  }

  async findUser(id: string) {
    const data = await getUserById(id);
    this.selectedShopPlayerCustomerId = data.stripeCustomerId;
  }
  centsToUsd(amount: number) {
    return {
      usdFormatted: (amount / 100).toFixed(2),
      usd: amount / 100,
      cents: amount
    };
  }
}
