var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("height: " + _vm.height))},[_c('app-calendar',{ref:"appCalendar",attrs:{"sourceEvents":_vm.events,"isLoading":_vm.isLoading,"canCreate":_vm.canCreate,"newEventTitle":_vm.newEventTitle,"showBackButton":_vm.showBackButton,"calendarTitle":_vm.calendarTitle},on:{"fetchMore":_vm.fetchMore,"newEvent":_vm.newEvent,"changeEvent":_vm.changeEvent,"clickEvent":_vm.clickEvent}}),_c('calendar-event-action-list',{attrs:{"selectedEvent":_vm.selectedEvent},on:{"view":function($event){_vm.dialogOpen = true;
      _vm.actionSelected = 'View';},"reschedule":function($event){_vm.dialogOpen = true;
      _vm.actionSelected = 'Reschedule';},"reschedule-current":function($event){_vm.dialogOpen = true;
      _vm.actionSelected = 'Reschedule-Current';},"checkIn":function($event){_vm.dialogOpen = true;
      _vm.actionSelected = 'Check-In';},"players":function($event){_vm.dialogOpen = true;
      _vm.actionSelected = 'Players';}},model:{value:(_vm.actionsOpen),callback:function ($$v) {_vm.actionsOpen=$$v},expression:"actionsOpen"}}),_c('app-dialog',{model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[(_vm.mode === 'change' && _vm.selectedEvent !== null)?_c('event-update-confirmation',{attrs:{"draggedEvent":_vm.selectedEvent},on:{"confirm":function($event){_vm.dialogOpen = false},"close":function($event){_vm.dialogOpen = false}}}):(
        _vm.mode === 'new' &&
        _vm.calendarType !== 'Program' &&
        _vm.calendarType !== 'Coach'
      )?_c('CreateOrEditPL',{attrs:{"selectedDate":_vm.selectedEvent,"coachId":_vm.userToLoad},on:{"close":function($event){_vm.dialogOpen = false},"updated":_vm.lessonUpdated}}):(
        _vm.mode === 'new' &&
        _vm.calendarType !== 'Program' &&
        _vm.calendarType === 'Coach'
      )?_c('request-private-lesson',{attrs:{"selectedDate":_vm.selectedEvent,"coachId":_vm.userToLoad},on:{"close":function($event){_vm.dialogOpen = false},"updated":_vm.lessonUpdated}}):(_vm.mode === 'new' && _vm.calendarType === 'Program')?_c('make-up-form',{attrs:{"selectedDate":_vm.selectedEvent},on:{"close":function($event){_vm.dialogOpen = false}}}):(_vm.actionSelected)?_c(_vm.actionComponent,{tag:"component",attrs:{"selectedDate":_vm.selectedEvent,"existingLesson":_vm.selectedPrivateLesson,"existingProgram":_vm.selectedProgram},on:{"close":function($event){_vm.dialogOpen = false},"updated":_vm.lessonUpdated}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }