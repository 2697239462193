var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-event-action-list-container"},[(!_vm.isDesktop)?_c('v-bottom-sheet',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{staticClass:"calendar-event-action-list-card",attrs:{"loading":_vm.isLoading}},[_c('div',{staticClass:"handle"}),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 py-2"},[_vm._v(" "+_vm._s(_vm.sheetTitle)+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-3 text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.sheetTime)+" ")])],1)],1),_c('v-divider'),_vm._l((_vm.actions),function(action){return _c('calendar-event-action',{key:action.identifier,attrs:{"action":action,"loading":_vm.buttonStatus[action.identifier]},on:{"interact":_vm.performAction}})})],2)],1)],1):_c('v-overlay',{attrs:{"opacity":"0.2"},on:{"click":function($event){_vm.active = false}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"event-actions-card",style:(("top:" + (_vm.eventDisplay.top) + ";left:" + (_vm.eventDisplay.left) + ";width:" + (_vm.eventDisplay.width) + "; bottom:" + (_vm.eventDisplay.bottom))),attrs:{"light":""},on:{"click":_vm.preventBubbling}},[_c('span',{staticClass:"arrow",class:_vm.eventDisplay.direction}),_c('v-list',{staticClass:"actions-list py-0",class:((_vm.eventDisplay.direction) + " d-flex " + (_vm.eventDisplay.direction.startsWith('bottom')
            ? 'flex-column-reverse'
            : 'flex-column')),attrs:{"light":"","dense":""}},[_c('v-list-item',{staticClass:"action-list-title"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-1 py-2"},[_vm._v(" "+_vm._s(_vm.sheetTitle)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.sheetTime)+" ")])],1)],1),_c('v-divider'),_vm._l((_vm.actions),function(action){return _c('calendar-event-action',{key:action.identifier,attrs:{"action":action,"loading":_vm.buttonStatus[action.identifier]},on:{"interact":_vm.performAction}})})],2)],1)]),_c('v-dialog',{attrs:{"persistent":"","width":"auto"},model:{value:(_vm.cancelConfirmation),callback:function ($$v) {_vm.cancelConfirmation=$$v},expression:"cancelConfirmation"}},[_c('v-card',{staticClass:"event-update-confirmation-container text-center",attrs:{"max-width":"350","loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.cancelTitle)+" ")]),(_vm.cancelBody.length > 0)?_c('v-card-text',{staticClass:"text-center justify-center"},[_vm._v(" "+_vm._s(_vm.cancelBody)+" ")]):_vm._e(),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-center w-100"},[_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.isLoading,"color":"error","text":""},on:{"click":function($event){_vm.cancelConfirmation = false;
              _vm.active = false;}}},[_vm._v(" No, Dismiss ")]),_c('v-btn',{attrs:{"loading":_vm.isLoading,"depressed":"","color":"primary"},on:{"click":function($event){return _vm.cancelEvent()}}},[_vm._v(" "+_vm._s(_vm.cancelButtonText)+" ")])],1),_c('br'),(
            (_vm.selectedPrivateLesson && _vm.selectedPrivateLesson.repeat) ||
            _vm.selectedProgram
          )?_c('v-btn',{attrs:{"block":"","color":"error","disabled":_vm.isLoading},on:{"click":function($event){return _vm.cancelEvent(true)}}},[_vm._v(" Cancel All ")]):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.pricePending),callback:function ($$v) {_vm.pricePending=$$v},expression:"pricePending"}},[_c('v-card',{staticClass:"event-update-confirmation-container text-center",attrs:{"max-width":"350","loading":_vm.isLoading}},[_c('v-card-title',{staticClass:"text-center justify-center mb-3"},[_vm._v(" Pricing not set ")]),_c('v-card-subtitle',{staticClass:"text-center justify-center pb-2"},[_vm._v(" "+_vm._s(_vm.pricingNotice)+" ")]),_c('v-card-actions',{staticClass:"align-center justify-space-between"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.isLoading,"color":"error"},on:{"click":function($event){_vm.pricePending = false;
            _vm.cancelConfirmation = false;
            _vm.active = false;}}},[_vm._v(" Skip for now ")]),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"primary"},on:{"click":function($event){return _vm.$router.push('/profile')}}},[_vm._v(" Let's go "),_c('arrow-right',{staticClass:"ml-2",attrs:{"stroke":"white"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }