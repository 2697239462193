



































import { fromFirestore, forFirestore } from "@/utils/parser";
import { EVENTS_TABLE_NAME, Event, applyTimeToDay } from "@sportango/backend";
import { DB } from "@/firebase";
import { EmitClose, LoadingMixin } from "@/mixins/Helpers";
import { CalendarEvent, UpdateEventTimings } from "@/store/actions/events";
import {
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  where,
  writeBatch
} from "@firebase/firestore";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "event-update-confirmation"
})
export default class EventUpdateConfirmation extends mixins(
  EmitClose,
  LoadingMixin
) {
  @Prop({ required: true })
  private draggedEvent!: CalendarEvent;

  async confirm() {
    if (
      this.draggedEvent.start &&
      this.draggedEvent.end &&
      this.draggedEvent.id
    ) {
      this.isLoading = true;
      const updatePayload: UpdateEventTimings = {
        id: this.draggedEvent.id,
        start: new Date(this.draggedEvent.start),
        end: new Date(this.draggedEvent.end)
      };
      await this.$store.dispatch("updateEventTimings", updatePayload);
      this.$emit("confirm");
      this.isLoading = false;
    }
  }

  async updateAll() {
    this.isLoading = true;
    if (this.draggedEvent) {
      const newStartTime = dayjs(this.draggedEvent.start).format("HH:mm");
      const newEndTime = dayjs(this.draggedEvent.end).format("HH:mm");
      const batch = writeBatch(DB);
      const events = (
        await getDocs(
          query(
            collection(DB, EVENTS_TABLE_NAME),
            where("parentItem", "==", this.draggedEvent.parentItem),
            where(
              "startTime",
              ">=",
              Timestamp.fromDate(new Date(Number(this.draggedEvent.start)))
            )
          )
        )
      ).docs.map((d) => fromFirestore<Event>(d, "id"));
      events.forEach(({ id, startTime, endTime }) => {
        const payload: Partial<Event> = {
          startTime: applyTimeToDay(dayjs(startTime), newStartTime).toDate(),
          endTime: applyTimeToDay(dayjs(endTime), newEndTime).toDate()
        };
        batch.update(
          doc(collection(DB, EVENTS_TABLE_NAME), id),
          forFirestore(payload)
        );
      });
      batch.update(
        doc(collection(DB, EVENTS_TABLE_NAME), this.draggedEvent.id),
        forFirestore({
          startTime: applyTimeToDay(
            dayjs(this.draggedEvent.start),
            newStartTime
          ).toDate(),
          endTime: applyTimeToDay(
            dayjs(this.draggedEvent.end),
            newEndTime
          ).toDate()
        })
      );
      await batch.commit();
    }
    this.isLoading = false;
    this.$emit("confirm");
  }

  private get newTime(): string {
    return dayjs(this.draggedEvent.start).format("h:mm A");
  }
}
