























































































import Component, { mixins } from "vue-class-component";
import AppCalendar from "@/components/Calendar/AppCalendar.vue";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import {
  CalendarEvent,
  getEventsForUserQuery,
  GetEventsOptions,
  getProgramEventsQuery,
  GetUserEventOptions,
  parseEventsForCalendar
} from "@/store/actions/events";
import { USER_TYPES } from "@/store/actions/users";
import EventUpdateConfirmation from "@/components/Calendar/EventUpdateConfirmation.vue";
import CalendarEventActionList from "@/components/Calendar/CalendarEventActionList.vue";
import CreateOrEditPL from "@/components/PrivateLesson/CreateOrEditPL.vue";
import EditPLEvent from "@/components/PrivateLesson/EditPLEvent.vue";
import { Prop, Ref, Watch } from "vue-property-decorator";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { Private, Program, Event, EventEntity } from "@sportango/backend";
import CloseIcon from "@/assets/icons/saxcons/close-circle-broken.svg";
import PrivateLessonCard from "@/components/PrivateLesson/PrivateLessonCard.vue";
import ProgramCard from "@/components/programs/ProgramCard.vue";
import TickIcon from "@/assets/icons/saxcons/tick-circle-broken.svg";
import {
  collection,
  onSnapshot,
  query,
  QuerySnapshot
} from "@firebase/firestore";
import { DB } from "@/firebase";
import { EVENTS_TABLE_NAME } from "@sportango/backend";
import { fromFirestore } from "@/utils/parser";
import ProgramPlayersCheckIn from "@/components/programs/ProgramPlayersCheckIn.vue";
import { Unsubscribe } from "@firebase/util";
import { RouteWatcherMixin } from "@/mixins/RouteWatcher";
import { EventActionName } from "@/components/Calendar/types";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import MakeUpForm from "@/components/programs/MakeupForm.vue";
import MakeupPlayers from "@/components/programs/MakeupPlayers.vue";
import EventPlayers from "@/components/programs/EventPlayers.vue";
import RequestPrivateLesson from "@/components/PrivateLesson/RequestPrivateLesson.vue";
import RequestedDialog from "@/components/Dialogs/RequestedDialog.vue";
import dayjs from "dayjs";
import { IInfoWindow } from "@/store/types";
@Component({
  name: "calendar",
  components: {
    EventPlayers,
    MakeUpForm,
    AppCalendar,
    CloseIcon,
    EventUpdateConfirmation,
    CreateOrEditPL,
    PrivateLessonCard,
    TickIcon,
    ProgramCard,
    CalendarEventActionList,
    ProgramPlayersCheckIn,
    EditPLEvent,
    AppDialog,
    MakeupPlayers,
    RequestPrivateLesson,
    RequestedDialog
  }
})
export default class Calendar extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  CurrentUserMixin,
  RouteWatcherMixin
) {
  private dialogOpen = false;
  private actionsOpen = false;
  private mode: "click" | "change" | "new" | null = null;
  private selectedEvent: CalendarEvent | null = null;
  private refreshEvents = false;
  @Ref("appCalendar")
  private appCalendar!: AppCalendar;
  private subscription: Unsubscribe | undefined;
  private actionSelected: EventActionName = "View";
  @Prop({ required: false, default: "User" })
  calendarType!: "User" | "Coach" | "Program";
  userToLoad = "";
  propertiesToWatch = ["dialogOpen", "actionsOpen"];
  @Prop({ required: false, default: "100%", type: String })
  height!: string;

  get events(): Array<CalendarEvent> {
    if (this.refreshEvents) {
      this.refreshEvents = false;
    }
    return parseEventsForCalendar(this.$store.getters.events).map((e) => {
      if (e.eventType === "PROGRAM") {
        e.canDrag = this.currentUser?.permissions.hasAdminAccess;
      } else if (e.eventType === "PRIVATE_LESSON") {
        e.canDrag = this.currentUser?.permissions.hasCoachAccess;
      }
      if (e.start && e.start < new Date()) {
        e.canDrag = false;
      }
      return e;
    });
  }
  get canCreate(): boolean | undefined {
    if (this.calendarType === "Coach") {
      return this.currentUser?.permissions.hasPlayerAccess;
    } else if (this.calendarType === "Program") {
      return this.currentUser?.permissions.hasAdminAccess;
    }
    return this.currentUser?.permissions?.hasCoachAccess;
  }
  get newEventTitle(): string {
    if (this.canCreate) {
      if (this.calendarType === "Program") {
        return "New Program Makeup";
      }
      if (this.currentUser?.permissions.hasAdminAccess) {
        return "New Private Lesson";
      }
      if (this.currentUser?.permissions.hasCoachAccess) {
        return "New Private Lesson";
      }
      if (this.currentUser?.permissions.hasPlayerAccess) {
        return "Request Private Lesson";
      }
    }
    return "";
  }

  get contentMaxHeight(): string {
    if (this.isDesktop) {
      return `620px`;
    } else {
      return `${innerHeight}px`;
    }
  }

  get cardWidth(): number {
    if (this.isDesktop) {
      return innerWidth * 0.6;
    } else {
      return innerWidth;
    }
  }

  get selectedProgram(): Program | null {
    return (
      this.$store.getters.programs.find(
        (p) => this.selectedEvent?.parentItem === p.id
      ) || null
    );
  }

  get selectedPrivateLesson(): Private | null {
    return (
      this.$store.getters.privateLessons.find(
        (p) => this.selectedEvent?.parentItem === p.id
      ) || null
    );
  }

  get actionComponent(): string {
    if (this.selectedEvent?.eventType === "PRIVATE_LESSON") {
      switch (this.actionSelected) {
        case "View":
          return "PrivateLessonCard";
        case "Reschedule":
          return "CreateOrEditPL";
        case "Reschedule-Current":
          return "EditPLEvent";
      }
    } else if (this.selectedEvent?.eventType === "PROGRAM") {
      switch (this.actionSelected) {
        case "View":
          return "ProgramCard";
        case "Check-In":
          return "ProgramPlayersCheckIn";
        case "Players":
          if (this.selectedEvent.isMakeup) {
            return "MakeupPlayers";
          }
          return "EventPlayers";
      }
    }
    return "";
  }

  get showBackButton(): boolean {
    if (this.$route.params.userId) {
      return true;
    }
    return false;
  }

  get calendarTitle(): string {
    if (this.userToLoad !== this.currentUser?.uid) {
      return `${
        this.$store.getters.users.find((u) => u.uid === this.userToLoad)
          ?.displayName
      }`;
    }
    return "Schedule";
  }
  get formatTime(): string {
    return (
      (this.selectedEvent &&
        dayjs(this.selectedEvent.start).format("MMM DD, YYYY, hh:mm a")) ||
      ""
    );
  }
  private async fetchMore(option: GetEventsOptions) {
    if (this.currentUser && this.currentUser.permissions) {
      this.isLoading = true;
      let userType: USER_TYPES = "ADMIN";
      if (this.currentUser.permissions.hasPlayerAccess) {
        userType = "PLAYER";
      }
      if (this.currentUser.permissions.hasCoachAccess) {
        userType = "COACH";
      }
      if (this.currentUser.permissions.hasAdminAccess) {
        userType = "ADMIN";
      }
      const options: GetUserEventOptions = {
        ...option,
        userId: this.currentUser?.uid,
        userType
      };
      if (this.calendarType === "Coach") {
        options.userType = "COACH";
        options.userId = this.userToLoad;
      }
      let extraQueries = getEventsForUserQuery(options);
      if (this.calendarType === "Program") {
        extraQueries = getProgramEventsQuery({
          ...options
        });
      }
      const q = query(collection(DB, EVENTS_TABLE_NAME), ...extraQueries);
      if (this.subscription) {
        this.subscription();
      }
      this.subscription = onSnapshot(q, this.commitEvents);
      if (this.dialogOpen) {
        this.dialogOpen = false;
      }
    }
  }

  private triggerMore() {
    if (
      this.currentUser?.permissions.hasPlayerAccess &&
      !this.currentUser?.permissions.hasCoachAccess
    ) {
      const infoWindow: IInfoWindow = {
        type: "SUCCESS",
        open: true,
        headingContent: "Requested!",
        actionText: "Ok,got it.",
        bodyContent: `Your request for
          <strong> Private Lesson </strong> with
          <strong> ${this.calendarTitle}, ${this.formatTime}</strong>
          was sent.`
      };
      this.$store.commit("infoWindow", infoWindow);
    }
    this.appCalendar.fetchMoreEvents();
  }

  private async newEvent(e: CalendarEvent) {
    this.selectedEvent = e;
    this.dialogOpen = true;
    this.mode = "new";
  }
  private async changeEvent(e: CalendarEvent) {
    this.selectedEvent = e;
    this.dialogOpen = true;
    this.mode = "change";
  }
  private async clickEvent(e: CalendarEvent) {
    this.selectedEvent = e;
    this.actionsOpen = true;
    this.mode = "click";
  }

  private async commitEvents({ docs }: QuerySnapshot) {
    this.isLoading = true;
    const events = docs.map(
      (d) => new EventEntity(fromFirestore<Event>(d, "id"))
    );
    this.$store.commit("events", events);
    await this.$store.dispatch(
      "getPrivateLessonsById",
      this.$store.getters.events
        .filter((e) => e.eventType === "PRIVATE_LESSON")
        .map((e) => e.parentItem)
    );
    await this.$store.dispatch(
      "getProgramById",
      this.$store.getters.events
        .filter((e) => e.eventType === "PROGRAM")
        .map((e) => e.parentItem)
    );
    // create array of players in events not in programs or private lessons
    let eventPlayers: string[] = [];
    events.forEach((e) => {
      if (e.playerIds) {
        eventPlayers = [...eventPlayers, ...e.playerIds];
      }
    });
    this.$store.dispatch("getUsersById", eventPlayers);
    this.isLoading = false;
  }
  private async lessonUpdated() {
    setTimeout(this.triggerMore, 1000);
  }
  @Watch("dialogOpen")
  watchDialog() {
    if (!this.dialogOpen) {
      this.mode = null;
      this.refreshEvents = true;
      this.actionsOpen = false;
    }
  }

  @Watch("$store.getters.events")
  updateSelectedEvent(newVal: Array<Event>) {
    const foundSelected = newVal.find((e) => e.id === this.selectedEvent?.id);
    if (foundSelected) {
      this.selectedEvent = parseEventsForCalendar([foundSelected])[0];
    }
  }

  async mounted() {
    if (this.userToLoad !== this.currentUser?.uid) {
      await this.$store.dispatch("getUsersById", this.userToLoad);
    }
  }

  beforeMount() {
    if (this.$route.params.userId) {
      this.userToLoad = this.$route.params.userId;
    } else if (this.currentUser && this.currentUser.uid) {
      this.userToLoad = this.currentUser.uid;
    }
  }
}
