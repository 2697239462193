



























































































































import { Private, PrivateEntity } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import MobileAutoComplete from "@/components/Navigation/MobileAutoComplete.vue";
import {
  CourtsMixin,
  CurrentUserMixin,
  EmitClose,
  LoadingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { requiredFieldRule, ValidationRules } from "@/utils/validation";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import DialogTimePicker from "@/components/Inputs/DialogTimePicker.vue";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import { Event } from "@sportango/backend";
import { applyDurationToDay, applyTimeToDay } from "@sportango/backend";

@Component({
  name: "edit-private-lesson-event",
  components: {
    UsersAutoComplete,
    MobileAutoComplete,
    DialogDatePicker,
    DialogTimePicker
  }
})
export default class EditPLEvent extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  ResponsiveMixin,
  EmitClose,
  CourtsMixin
) {
  @Prop({ required: false })
  private existingLesson: Private | undefined;
  @Prop({ required: false })
  private selectedDate: CalendarEvent | undefined;
  private lesson: Private;
  private requiredFieldRule = requiredFieldRule;
  private endDateRules: ValidationRules;
  private isValid = false;
  private askingConfirmation = false;
  private get isReallyValid(): boolean {
    if (this.lesson.repeat) {
      if (!this.lesson.days) {
        return false;
      }
      return this.isValid && this.lesson.days.length > 0;
    }
    return this.isValid;
  }
  private validateEndDate(v: string) {
    if (this.lesson.startDate === undefined) {
      return "Please select Start Date First";
    }
    if (new Date(v) < new Date(this.lesson.startDate)) {
      return "End Date must be after Start Date";
    }
    return true;
  }
  private options = Private.options;
  private duration = 0;

  private get isCoach(): boolean | undefined {
    if (this.currentUser?.permissions.hasCoachAccess) {
      if (this.currentUser?.permissions.hasAdminAccess) {
        return false;
      }
      this.lesson.coach = this.currentUser?.uid;
      return true;
    }
    return false;
  }

  private get durationValue(): Private.DurationEnum {
    return this.options.duration[this.duration] as Private.DurationEnum;
  }

  constructor() {
    super();
    this.lesson = new PrivateEntity({});
    this.endDateRules = [
      ...requiredFieldRule("End Date"),
      this.validateEndDate
    ];
  }

  mounted() {
    this.resetForm();
  }

  @Watch("existingLesson")
  @Watch("selectedDate")
  resetForm() {
    if (this.existingLesson) {
      this.lesson = new PrivateEntity(this.existingLesson);
      switch (this.lesson.duration) {
        case "0.5 Hr":
          this.duration = 0;
          break;
        case "1 Hr":
          this.duration = 1;
          break;
        case "1.5 Hrs":
          this.duration = 2;
          break;
        case "2 Hrs":
          this.duration = 3;
          break;
      }
    }
    if (this.selectedDate) {
      this.lesson = new PrivateEntity({
        ...this.lesson,
        startTime: dayjs(new Date(Number(this.selectedDate.start))).format(
          "HH:mm"
        ),
        startDate: new Date(Number(this.selectedDate.start)),
        endDate: new Date(Number(this.selectedDate.end))
      });
      const diff = Number(this.lesson.endDate) - Number(this.lesson.startDate);
      if (!isNaN(diff)) {
        if (diff < 3600000) {
          this.duration = 0;
        } else if (diff === 3600000 && diff < 5400000) {
          this.duration = 1;
        } else if (diff === 5400000 && diff < 7200000) {
          this.duration = 2;
        } else {
          this.duration = 3;
        }
      }
      this.lesson.days = [
        dayjs(this.selectedDate.start).format("ddd") as Private.DaysEnum
      ];
    }
  }

  private get cardWidth(): number {
    if (this.isDesktop) {
      return innerWidth * 0.6;
    } else {
      return innerWidth;
    }
  }

  private get daysOptions(): Array<Record<"text" | "value", string>> {
    return this.options.days.map((d) => {
      return this.isDesktop
        ? {
            text: d,
            value: d
          }
        : {
            text: d[0],
            value: d
          };
    });
  }

  async save(): Promise<void> {
    this.isLoading = true;
    if (this.selectedDate?.id) {
      const newStartTime = applyTimeToDay(
        dayjs(this.lesson.startDate),
        this.lesson.startTime || "12:00 AM"
      );
      let event: Partial<Event> = {
        id: this.selectedDate?.id,
        startTime: newStartTime.toDate(),
        endTime: applyDurationToDay(newStartTime, this.durationValue).toDate(),
        isRequest: false
      };
      if (this.lesson.coach) {
        event = {
          ...event,
          coaches: [this.lesson.coach]
        };
      }
      if (this.lesson.privatePlayers) {
        event = {
          ...event,
          players: this.lesson.privatePlayers.map((p) => {
            return {
              uid: p
            };
          })
        };
      }
      await this.$store.dispatch("updateEvent", {
        ...event
      });
    }
    this.$emit("updated");
    this.isLoading = false;
  }
}
