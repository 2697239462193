














































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { EventAction } from "./types";
import EditIcon from "@/assets/icons/saxcons/edit-linear.svg";
import ViewIcon from "@/assets/icons/saxcons/info-circle-linear.svg";
import CancelIcon from "@/assets/icons/saxcons/close-circle-linear.svg";
import CheckInIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import RescheduleIcon from "@/assets/icons/saxcons/calendar-edit-linear.svg";
import ConfirmIcon from "@/assets/icons/saxcons/calendar-tick-linear.svg";
import PlayersIcon from "@/assets/icons/saxcons/profile-2user-linear.svg";

@Component({
  name: "calendar-event-action",
  components: {
    EditIcon,
    ViewIcon,
    CancelIcon,
    CheckInIcon,
    RescheduleIcon,
    ConfirmIcon,
    PlayersIcon
  }
})
export default class CalendarEventAction extends Vue {
  @Prop({ required: true })
  action!: EventAction;
  @Prop({ required: false })
  loading!: boolean | string | undefined;

  get icon(): string | null {
    switch (this.action.identifier) {
      case "View":
        return "ViewIcon";
      case "Reschedule":
        return "EditIcon";
      case "Reschedule-Current":
        return "RescheduleIcon";
      case "Cancel":
        return "CancelIcon";
      case "Check-In":
        return "CheckInIcon";
      case "Confirm":
        return "ConfirmIcon";
      case "Players":
        return "PlayersIcon";
      case "Skip":
        return "CancelIcon";
      case "Accept":
        return "ConfirmIcon";
      case "Request":
        return "PlayersIcon";
    }
    return null;
  }

  get subTitle(): string | null {
    switch (this.action.identifier) {
      case "View":
        return "View more information about this event";
    }
    return null;
  }
}
