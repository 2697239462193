
































































































































































































import { Event } from "@sportango/backend";
import { Program } from "@sportango/backend";
import { BaseUser } from "@sportango/backend";
import { CaptureEvent, CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import { EventPlayerInfo } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import ProgramCheckInAdd from "@/components/programs/ProgramCheckInAdd.vue";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import { NewPlayerConfig } from "@/components/programs/types";
import PlayersIcon from "@/assets/icons/saxcons/profile-2user-linear.svg";
import { WatchLoading } from "@/decorators/Loading";
import MoreIcon from "@/assets/icons/saxcons/more-linear.svg";
import CancelIcon from "@/assets/icons/saxcons/close-circle-linear.svg";
import TrashIcon from "@/assets/icons/saxcons/trash-linear.svg";
import MenuOrBottomSheet from "@/components/Navigation/MenuOrBottomSheet.vue";
import TickIcon from "@/assets/icons/saxcons/tick-circle-bold.svg";

@Component({
  name: "event-players",
  components: {
    TickIcon,
    TrashIcon,
    CancelIcon,
    MenuOrBottomSheet,
    MoreIcon,
    PlayersIcon,
    AppDialog,
    ProgramCheckInAdd,
    UsersAutoComplete
  }
})
export default class EventPlayers extends mixins(
  CurrentUserMixin,
  LoadingMixin,
  ResponsiveMixin,
  CaptureEvent
) {
  @Prop({ required: true })
  selectedDate!: CalendarEvent;
  @Prop({ required: true })
  existingProgram!: Program;
  newUser = "";
  isAdding = false;
  isBeingAdded = false;
  activePlayer: (BaseUser & EventPlayerInfo) | null = null;

  get actionTitle(): string {
    return dayjs(this.selectedDate.start).format("dddd, MMM D");
  }

  get availableCapacity(): number {
    if (this.existingProgram.capacity && this.selectedDate.players) {
      return (
        this.existingProgram.capacity -
        this.selectedDate.players.filter((p) => !p.notAttending).length
      );
    }
    return 0;
  }

  get allowAdding(): boolean {
    if (this.existingProgram.capacity) {
      return this.availableCapacity > 0;
    }
    return false;
  }

  get players(): Array<(BaseUser & EventPlayerInfo) | undefined> {
    return (this.selectedDate.players || [])
      .map((p) => {
        const player = this.$store.getters.users.find((u) => u.uid == p.uid);
        if (player) {
          return {
            ...player,
            ...p
          };
        }
      })
      .filter((p) => p !== undefined);
  }
  get usersToIgnore(): Array<string> {
    if (this.selectedDate.players) {
      return this.selectedDate.players
        .map((p) => p.uid || "")
        .filter((u) => u.trim() !== "");
    }
    return [];
  }

  get statusOptionColor(): string {
    if (this.activePlayer?.notAttending === true) {
      return "success";
    }
    return "warning";
  }

  async addPlayer(p: NewPlayerConfig): Promise<void> {
    this.isBeingAdded = true;
    const payLoad: Partial<Event> = {
      id: this.selectedDate.id,
      players: [
        ...(this.selectedDate.players || []),
        {
          uid: p.player,
          playerType: p.type
        }
      ]
    };
    await this.$store.dispatch("updateEvent", payLoad);
    this.isBeingAdded = false;
    this.isAdding = false;
  }

  @WatchLoading()
  async markAsNotAttending() {
    if (this.activePlayer) {
      // Update the player status
      const payLoad: Partial<Event> = {
        id: this.selectedDate.id,
        players: this.selectedDate.players?.map((p) => {
          if (p.uid === this.activePlayer?.uid) {
            if (p.notAttending === true) {
              p.notAttending = undefined;
            } else {
              p.notAttending = true;
            }
          }
          return p;
        })
      };
      await this.$store.dispatch("updateEvent", payLoad);
    }
    this.isEventCaptured = false;
  }

  @WatchLoading()
  async removePlayer() {
    if (this.activePlayer) {
      // Update the player status
      const payLoad: Partial<Event> = {
        id: this.selectedDate.id,
        players: this.selectedDate.players?.filter((p) => {
          if (p.uid === this.activePlayer?.uid) {
            return false;
          }
          return true;
        })
      };
      await this.$store.dispatch("updateEvent", payLoad);
    }
    this.isEventCaptured = false;
  }
}
