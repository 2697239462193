
































import { Private } from "@sportango/backend";
import { Program } from "@sportango/backend";
import { BaseUser } from "@sportango/backend";
import { AllUsersMixin, CurrentUserMixin } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TickIcon from "@/assets/icons/saxcons/tick-circle-bold.svg";

@Component({
  name: "calendar-event-card",
  components: {
    TickIcon
  }
})
export default class CalendarEventCard extends mixins(
  ResponsiveMixin,
  CurrentUserMixin,
  AllUsersMixin
) {
  @Prop({ required: true, type: Object })
  event!: CalendarEvent;

  get title(): string | undefined {
    if (this.event.eventType === "PRIVATE_LESSON") {
      return `${this.time}`;
    }
    return this.event.name;
  }

  get time(): string {
    return `${dayjs(new Date(Number(this.event.start))).format(
      "h:mm A"
    )} - ${dayjs(new Date(new Date(Number(this.event.end)))).format("h:mm A")}`;
  }

  get coaches(): Array<BaseUser | undefined> {
    return [...(this.event.coaches || [])]
      .map((c) => this.allUsers.find((u) => u.uid === c))
      .filter((u) => u !== undefined);
  }

  get players(): Array<BaseUser> {
    return this.allUsers.filter((u) =>
      this.event.players?.find((p) => p.uid === u.uid)
    );
  }

  get subTitle(): string | null {
    if (this.existingLesson) {
      if (this.existingLesson.coach === this.currentUser?.uid) {
        return (
          "Private with: " +
          this.players
            .map((u) => {
              if (u.displayName) {
                return u.displayName.split(" ")[0];
              }
              return "";
            })
            .filter((u) => u !== "")
            .join(", ")
        );
      } else {
        if (this.coaches && this.coaches[0]) {
          return `Private with: ${this.coaches[0].displayName}`;
        }
      }
    } else if (this.existingProgram) {
      if (this.coaches && this.coaches[0]) {
        return `Head Coach: ${this.coaches[0].displayName}`;
      }
    }
    return null;
  }

  get existingLesson(): Private | null {
    if (this.event.eventType === "PRIVATE_LESSON") {
      return (
        this.$store.getters.privateLessons.find(
          (p) => this.event.parentItem === p.id
        ) || null
      );
    }
    return null;
  }

  get existingProgram(): Program | null {
    if (this.event.eventType === "PROGRAM") {
      return (
        this.$store.getters.programs.find(
          (p) => this.event.parentItem === p.id
        ) || null
      );
    }
    return null;
  }

  get finalColor(): string | undefined {
    if (this.event.players) {
      const currentPlayerEventInfo = this.event.players.find(
        (p) => p.uid === this.currentUser?.uid
      );
      if (currentPlayerEventInfo?.notAttending) {
        return "warning";
      }
    }
    return this.event.color;
  }
}
