















































































import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { PlayerCheckInDetails } from "./types";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

@Component({
  name: "player-check-in-control",
  components: {
    LottieAnimation
  }
})
export default class PlayerCheckInControl extends mixins(ResponsiveMixin) {
  @Prop({ required: true })
  playerInfo!: PlayerCheckInDetails;

  get contentWidth(): string {
    if (this.isDesktop) {
      return "auto";
    } else {
      return `${window.innerWidth - 12 - 12 - 12 - 16 - 48 - 24 - 32}px`;
    }
  }
}
