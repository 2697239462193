





















































































































import {
  AllUsersMixin,
  CurrentUserMixin,
  LoadingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import {
  Event,
  ProgramMakeupPlayers,
  PROGRAMS_TABLE_NAME
} from "@sportango/backend";
import { Prop } from "vue-property-decorator";
import {
  MakeupCheckInDetails,
  NewPlayerConfig,
  PlayerCheckInDetails
} from "./types";
import { Program } from "@sportango/backend";
import { CalendarEvent } from "@/store/actions/events";
import PlayerCheckInControl from "./PlayerCheckInControl.vue";
import AppDialog from "../Navigation/AppDialog.vue";
import ProgramCheckInAdd from "./ProgramCheckInAdd.vue";
import { EventPlayerInfo } from "@sportango/backend";
import NoData from "@/components/common/NoData.vue";
import Divider from "@/components/common/Divider.vue";
import {
  collection,
  doc,
  DocumentSnapshot,
  onSnapshot,
  Unsubscribe
} from "@firebase/firestore";
import { DB } from "@/firebase";
import { WatchLoading } from "@/decorators/Loading";

@Component({
  name: "program-players-check-in",
  components: {
    Divider,
    NoData,
    ProgramCheckInAdd,
    PlayerCheckInControl,
    AppDialog
  }
})
export default class ProgramPlayersCheckIn extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  AllUsersMixin,
  CurrentUserMixin
) {
  @Prop({ required: true })
  selectedDate!: CalendarEvent;
  @Prop({ required: true })
  existingProgram!: Program;
  selectedPlayerType: "attendees" | "makeups" | "all" = "attendees";
  attendingUsers: Array<string> = [];
  addingNewPlayer = false;
  programWatch: Unsubscribe | null = null;

  get selectedEvent(): Event | undefined {
    const event = this.$store.getters.events.find(
      (e) => e.id === this.selectedDate.id
    );
    return event;
  }

  get attendees(): {
    freeTrials: Array<PlayerCheckInDetails>;
    dropIns: Array<PlayerCheckInDetails>;
    regular: Array<PlayerCheckInDetails>;
    total: Array<PlayerCheckInDetails>;
    length: number;
  } {
    const results: {
      freeTrials: Array<PlayerCheckInDetails>;
      dropIns: Array<PlayerCheckInDetails>;
      regular: Array<PlayerCheckInDetails>;
      total: Array<PlayerCheckInDetails>;
      length: number;
    } = {
      freeTrials: [],
      dropIns: [],
      regular: [],
      length: 0,
      total: []
    };
    this.allUsers.forEach((u) => {
      if (this.selectedEvent) {
        const ePI = this.selectedEvent.players?.find((p) => p.uid === u.uid);
        const val = {
          ...ePI,
          ...u,
          loading: this.attendingUsers.includes(u.uid)
        };
        if (ePI) {
          if (
            ePI &&
            (ePI.playerType === undefined || ePI.playerType === "DEFAULT")
          ) {
            results.regular.push(val);
          } else if (ePI.playerType === "FREE_TRIAL") {
            results.freeTrials.push(val);
          } else if (ePI?.playerType === "DROP_IN") {
            results.dropIns.push(val);
          }
        }
      }
    });
    results.length =
      results.freeTrials.length +
      results.dropIns.length +
      results.regular.length;
    results.total = [
      ...results.freeTrials,
      ...results.dropIns,
      ...results.regular
    ];
    return results;
  }

  get programMakeupDetails(): ProgramMakeupPlayers[] | undefined {
    if (this.existingProgram.id) {
      const programMakeupDetails =
        this.$store.getters.programMakeups[this.existingProgram.id];
      if (programMakeupDetails) {
        return programMakeupDetails.makeupPlayers;
      }
    }
    return undefined;
  }

  get makeUpPlayers(): Array<MakeupCheckInDetails> {
    const selectedEvent = this.selectedEvent;
    const result: MakeupCheckInDetails[] = [];
    this.allUsers.forEach((u) => {
      const eventPlayerInfo = selectedEvent?.players?.find(
        (p) => p.uid === u.uid
      );
      if (
        (this.existingProgram.programPlayers?.find((p) => p.uid === u.uid) &&
          !selectedEvent?.players?.find((p) => p.uid === u.uid)) ||
        eventPlayerInfo?.playerType === "MAKE_UP"
      ) {
        result.push({
          ...u,
          loading: this.attendingUsers.includes(u.uid),
          ...eventPlayerInfo,
          isAdded: eventPlayerInfo?.playerType === "MAKE_UP"
        });
      }
    });
    return result;
  }

  get reservedMakeupPlayers(): Array<MakeupCheckInDetails> {
    return this.makeUpPlayers.filter((p) => p.isAdded);
  }

  get unReservedMakeupPlayers(): Array<MakeupCheckInDetails> {
    return (this.programMakeupDetails || [])
      .filter((p) => !(this.selectedEvent?.playerIds || []).includes(p.id))
      .map((p) => {
        const playerDetails = this.$store.getters.users.find(
          (u) => u.uid === p.id
        );
        if (!playerDetails) {
          throw new Error("Got makeup player that is not a program player");
        }
        return {
          ...playerDetails,
          isAdded: false,
          loading: this.attendingUsers.includes(p.id),
          count: p.missedEvents.length
        };
      });
  }

  get checkedInPlayers(): Array<PlayerCheckInDetails> {
    return [...this.attendees.total, ...this.makeUpPlayers].filter(
      (p) => p?.hasAttended
    );
  }

  get availableCapacity(): number {
    if (this.existingProgram.capacity && this.selectedDate.players) {
      return (
        this.existingProgram.capacity -
        this.selectedDate.players.filter((p) => !p.notAttending).length
      );
    }
    return 0;
  }

  get allowAdding(): boolean {
    return this.availableCapacity > 0;
  }

  async setAttended(uid: string, insertType?: EventPlayerInfo.PlayerTypeEnum) {
    this.attendingUsers.push(uid);
    if (insertType === "DEFAULT") {
      this.isLoading = true;
    }
    if (this.selectedEvent) {
      let shouldAddNew = true;
      const payload: Partial<Event> = {
        id: this.selectedDate.id,
        players: (this.selectedEvent.players || []).map((p) => {
          if (p.uid === uid) {
            shouldAddNew = false;
            p.hasAttended = !p.hasAttended;
          }
          return p;
        })
      };
      if (shouldAddNew) {
        payload.players?.push({
          uid: uid,
          hasAttended: true,
          playerType: insertType
        });
      }
      await this.$store.dispatch("updateEvent", payload);
      this.attendingUsers = [];
    }
  }

  @WatchLoading()
  async watchProgram(doc: DocumentSnapshot) {
    await this.$store.dispatch("getLatestProgram", doc.id);
  }

  async addNewPlayer(newPlayer: NewPlayerConfig) {
    this.addingNewPlayer = false;
    await this.setAttended(newPlayer.player, newPlayer.type);
  }

  mounted() {
    this.programWatch = onSnapshot(
      doc(collection(DB, PROGRAMS_TABLE_NAME), this.existingProgram.id),
      this.watchProgram
    );
  }
  beforeDestroy() {
    if (this.programWatch) {
      this.programWatch();
    }
  }
}
