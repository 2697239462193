






































































import { Private } from "@sportango/backend";
import { BaseUser } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CourtIcon from "@/assets/icons/navigation/court.svg";
import TimeIcon from "@/assets/icons/saxcons/clock-linear.svg";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import UserChip from "@/components/common/UserChip.vue";
import { SportangoChip } from "../common/overrides";

@Component({
  name: "private-lesson-card",
  components: {
    SportangoChip,
    CourtIcon,
    TimeIcon,
    UserChip
  }
})
export default class PrivateLessonCard extends mixins(ResponsiveMixin) {
  @Prop({ required: true })
  private existingLesson!: Private;
  @Prop({ required: true })
  private selectedDate!: CalendarEvent;

  private get coach(): BaseUser | undefined {
    return this.$store.getters.users.find(
      (u) => u.uid === this.existingLesson.coach
    );
  }

  private get players(): Array<BaseUser> {
    return this.$store.getters.users.filter((u) =>
      this.existingLesson.privatePlayers?.includes(u.uid)
    );
  }

  private get lessonEventInfo(): string {
    return dayjs(this.selectedDate.start).format("h:mm A");
  }

  get courtNames(): string[] {
    return this.$store.getters.courts
      .filter((c) => this.existingLesson.court?.includes(c.id))
      .map((c) => c.name);
  }

  private get dateRange(): string {
    return `${dayjs(this.existingLesson.startDate).format(
      "MMM/DD/YYYY"
    )} - ${dayjs(this.existingLesson.endDate).format("MMM/DD/YYYY")}`;
  }
  async mounted() {
    await this.$store.dispatch("getUsersById", [
      this.existingLesson.coach,
      ...(this.existingLesson.privatePlayers || [])
    ]);
  }
}
