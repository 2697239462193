








































import { CalendarEvent } from "@/store/actions/events";
import { Program, ProgramMakeupPlayers } from "@sportango/backend";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import { NewPlayerConfig } from "./types";

@Component({
  name: "program-check-in-add",
  components: {
    UsersAutoComplete
  }
})
export default class ProgramCheckInAdd extends Vue {
  @Prop({ required: true })
  program!: Program;
  @Prop({ required: true })
  selectedDate!: CalendarEvent;
  isValid = false;
  newPlayer: NewPlayerConfig = {
    type: "FREE_TRIAL",
    player: ""
  };

  get usersToIgnore(): Array<string | undefined> {
    return (
      this.selectedDate.players
        ?.filter(
          (p) =>
            !p.notAttending || !(p.playerType !== "DEFAULT" || p.playerType)
        )
        .map((p) => p.uid) || []
    );
  }

  get eventPlayerIds(): Array<string> {
    const result: string[] = [];
    this.selectedDate.players?.forEach((p) => {
      if (p.uid) {
        result.push(p.uid);
      }
    });
    return result;
  }

  get programMakeupDetails(): ProgramMakeupPlayers[] | undefined {
    if (this.program.id) {
      const programMakeupDetails =
        this.$store.getters.programMakeups[this.program.id];
      if (programMakeupDetails) {
        return programMakeupDetails.makeupPlayers;
      }
    }
    return undefined;
  }

  get makeUpPlayers(): Array<string | undefined> | null {
    if (this.newPlayer.type === "MAKE_UP" && this.programMakeupDetails) {
      return this.programMakeupDetails.map((mP) => mP.id);
    }
    return null;
  }

  @Watch("newPlayer.type")
  resetPlayer() {
    this.newPlayer.player = "";
  }
}
