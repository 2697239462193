






























































































































import {
  Private,
  PrivateEntity,
  PRIVATE_LESSONS_TABLE_NAME
} from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import MobileAutoComplete from "@/components/Navigation/MobileAutoComplete.vue";
import {
  CourtsMixin,
  CurrentUserMixin,
  EmitClose,
  LoadingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { forFirestore } from "@/utils/parser";
import { requiredFieldRule, ValidationRules } from "@/utils/validation";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import DialogTimePicker from "@/components/Inputs/DialogTimePicker.vue";
import { CalendarEvent } from "@/store/actions/events";
import dayjs from "dayjs";
import { addDoc, collection } from "@firebase/firestore";
import { DB } from "@/firebase";
import DaysInput from "../Inputs/DaysInput.vue";
import { CoachAdmin } from "@/mixins/CoachAdmin";

@Component({
  name: "create-or-edit-private-lesson",
  components: {
    DaysInput,
    UsersAutoComplete,
    MobileAutoComplete,
    DialogDatePicker,
    DialogTimePicker
  }
})
export default class CreateOrEditPL extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  ResponsiveMixin,
  EmitClose,
  CourtsMixin,
  CoachAdmin
) {
  @Prop({ required: false })
  private existingLesson: Private | undefined;
  @Prop({ required: false })
  private selectedDate: CalendarEvent | undefined;
  @Prop({ required: false })
  private coachId: string | undefined;
  private lesson: Private;
  private requiredFieldRule = requiredFieldRule;
  private endDateRules: ValidationRules;
  private isValid = false;
  private askingConfirmation = false;

  private validateEndDate(v: string) {
    if (this.lesson.startDate === undefined) {
      return "Please select Start Date First";
    }
    if (new Date(v) < new Date(this.lesson.startDate)) {
      return "End Date must be after Start Date";
    }
    return true;
  }
  private options = Private.options;
  private duration = 0;

  private get durationValue(): Private.DurationEnum {
    return this.options.duration[this.duration] as Private.DurationEnum;
  }

  constructor() {
    super();
    this.lesson = new PrivateEntity({});
    this.endDateRules = [
      ...requiredFieldRule("End Date"),
      this.validateEndDate
    ];
  }

  mounted() {
    this.resetForm();
  }

  @Watch("existingLesson")
  @Watch("selectedDate")
  @Watch("coachId")
  @Watch("currentUser")
  resetForm() {
    if (this.existingLesson) {
      this.lesson = new PrivateEntity(this.existingLesson);
      switch (this.lesson.duration) {
        case "0.5 Hr":
          this.duration = 0;
          break;
        case "1 Hr":
          this.duration = 1;
          break;
        case "1.5 Hrs":
          this.duration = 2;
          break;
        case "2 Hrs":
          this.duration = 3;
          break;
      }
    } else if (this.selectedDate) {
      this.lesson = new PrivateEntity({
        ...this.lesson,
        startTime: dayjs(new Date(Number(this.selectedDate.start))).format(
          "HH:mm"
        ),
        startDate: new Date(Number(this.selectedDate.start)),
        endDate: new Date(Number(this.selectedDate.end))
      });
      const diff = Number(this.lesson.endDate) - Number(this.lesson.startDate);
      if (!isNaN(diff)) {
        if (diff < 3600000) {
          this.duration = 0;
        } else if (diff === 3600000 && diff < 5400000) {
          this.duration = 1;
        } else if (diff === 5400000 && diff < 7200000) {
          this.duration = 2;
        } else {
          this.duration = 3;
        }
      }
      this.lesson.days = [
        dayjs(this.selectedDate.start).format("ddd") as Private.DaysEnum
      ];
    }
    if (this.coachId) {
      this.lesson.coach = this.coachId;
    }
    if (
      this.isCurrentUserOnlyCoach &&
      this.$store.getters.featureFlags["coach-players"]
    ) {
      this.lesson.court = ["null"];
    }
  }

  private get cardWidth(): number {
    if (this.isDesktop) {
      return 550;
    } else {
      return innerWidth;
    }
  }

  get title(): string {
    if (this.existingLesson?.id) {
      return "Reschedule Private Lesson";
    } else {
      return "Create new Private Lesson";
    }
  }

  get actionText(): string {
    if (this.lesson.id) {
      return "Update";
    } else {
      return "Schedule";
    }
  }

  async save(): Promise<void> {
    this.isLoading = true;
    const data = new PrivateEntity({
      ...this.lesson,
      duration: this.durationValue,
      id: this.existingLesson?.id
    });
    if (this.existingLesson?.id) {
      data.editFrom = new Date(Number(this.selectedDate?.end));
      await this.$store.dispatch("updatePrivateLesson", data);
    } else {
      // Perform create
      const res = await addDoc(
        collection(DB, PRIVATE_LESSONS_TABLE_NAME),
        forFirestore(data)
      );
      data.id = res.id;
    }
    this.$emit("updated", data);
    this.isLoading = false;
  }
}
