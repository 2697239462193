import Vue from "vue";
import { VOverlay } from "vuetify/lib";

export default Vue.component("SportangoOverlay", {
  extends: VOverlay,
  props: {
    opacity: {
      type: Number,
      default: 1
    }
  }
});
