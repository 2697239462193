











































































































































import { Program, DaysEnum } from "@sportango/backend";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TimeIcon from "@/assets/icons/saxcons/clock-linear.svg";
import dayjs from "dayjs";
import { BaseUser } from "@sportango/backend";
import CourtIcon from "@/assets/icons/navigation/court.svg";
import CoachIcon from "@/assets/icons/navigation/coach.svg";
import PriceIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import { AllUsersMixin } from "@/mixins/Helpers";
import UserChip from "@/components/common/UserChip.vue";
import { CalendarEvent } from "@/store/actions/events";
import { WORDS } from "@/utils/validation";
import CalendarIcon from "@/assets/icons/saxcons/calendar-2-linear.svg";
import { SportangoChip } from "../common/overrides";

@Component({
  name: "program-card",
  components: {
    SportangoChip,
    CalendarIcon,
    TimeIcon,
    UserChip,
    CourtIcon,
    CoachIcon,
    PriceIcon
  }
})
export default class ProgramCard extends mixins(
  ResponsiveMixin,
  AllUsersMixin
) {
  @Prop({ required: true })
  private existingProgram!: Program | null;
  @Prop({ required: false })
  selectedDate!: CalendarEvent | undefined;
  readonly WORDS = WORDS;

  private get dateRange(): string {
    if (this.existingProgram) {
      return `${dayjs(this.existingProgram.startDate).format(
        "MMM, DD, YYYY"
      )} - ${dayjs(this.existingProgram.endDate).format("MMM, DD, YYYY")}`;
    }
    return "";
  }

  private get programDays(): Array<DaysEnum> {
    return this.existingProgram?.days || [];
  }

  get coaches(): Array<BaseUser> {
    if (this.existingProgram) {
      return this.allUsers.filter((u) => {
        return this.existingProgram?.coaches?.includes(u.uid);
      });
    }
    return [];
  }

  get courts(): Array<string> {
    return this.$store.getters.courts
      .filter((c) => this.existingProgram?.courts?.includes(c.id))
      .map((c) => c.name);
  }

  get numberOfWeeks(): string {
    if (this.existingProgram) {
      const weeks = Math.ceil(
        dayjs(this.existingProgram.endDate).diff(
          this.existingProgram.startDate,
          "days"
        ) / 7
      );
      if (weeks === 1 || weeks === 0) {
        return "1 Week session";
      } else {
        return `${weeks} Weeks session`;
      }
    }
    return "1 Week session";
  }
}
