import Vue from "vue";
import Component from "vue-class-component";

export interface Timer {
  name: string;

  /**
   * Callback method to run as part of the timeouts If you want to stop the
   * timer at any point, just return false
   */
  callback: () => void | false;

  /** Whether to run the callback the first time after mounted hook */
  autostart: boolean;

  /** Determines if the timer should keep running or only run once after timeout */
  repeat: boolean;

  /** Time between ticks in milliseconds */
  time: number;
  isRunning?: boolean;
}

export class TimerClass implements Timer {
  private timer!: NodeJS.Timeout | NodeJS.Timer;
  public name: string;
  public callback: () => false | void;
  public autostart: boolean;
  public repeat: boolean;
  public isRunning: boolean;
  public time: number;
  constructor(options: Timer) {
    (this.time = options.time), (this.autostart = options.autostart);
    this.callback = options.callback;
    this.name = options.name;
    this.repeat = options.repeat;
    this.isRunning = false;
  }
  start(T: TimerMixin): void {
    this.isRunning = true;
    this.timer = this.setTimer()(this.callback.bind(T), this.time);
    if (this.autostart) {
      this.callback();
    }
  }

  stop(): void {
    this.isRunning = false;
    this.removeTimer()(this.timer);
  }

  private setTimer(): typeof setInterval | typeof setTimeout {
    return this.repeat ? setInterval : setTimeout;
  }

  private removeTimer(): typeof clearInterval | typeof clearTimeout {
    return this.repeat ? clearInterval : clearTimeout;
  }
}

@Component
export class TimerMixin extends Vue {
  public timers: Array<Timer> = [];
  private internalTimers: Array<TimerClass> = [];
  constructor() {
    super();
  }
  mounted(): void {
    this.runTimers();
  }
  beforeDestroy(): void {
    this.internalTimers.forEach((t) => t.stop());
  }
  public runTimers(): void {
    this.internalTimers = this.timers.map((t) => new TimerClass(t));
    this.internalTimers.forEach((t) => t.start(this));
  }
}
