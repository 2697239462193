































































































































/* eslint-disable @typescript-eslint/ban-ts-comment */
import Component, { mixins } from "vue-class-component";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
import LeftIcon from "@/assets/icons/saxcons/chevron-left-linear.svg";
import RightIcon from "@/assets/icons/saxcons/chevron-right-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CalendarType, GetEventsOptions } from "@/store/actions/events";
import { Prop, Ref, Watch } from "vue-property-decorator";
import { VCalendar } from "@/types/Calendar";
import { TimerMixin } from "@/mixins/TimerMixin";
import { CalendarEvent } from "@/store/actions/events";
import { MouseDownTimeEventWrapper, MouseDownEventWrapper } from "./types";
import CalendarEventCard from "./CalendarEventCard.vue";
import BackIcon from "@/assets/icons/saxcons/arrow-left-linear.svg";

@Component({
  name: "app-calendar",
  components: {
    LeftIcon,
    RightIcon,
    CalendarEventCard,
    BackIcon
  }
})
export default class AppCalendar extends mixins(ResponsiveMixin, TimerMixin) {
  private thisDay: dayjs.Dayjs;
  private calendarType: CalendarType;
  private nowY: string;
  @Prop({ required: true })
  private sourceEvents!: Array<CalendarEvent>;
  @Prop({ required: false, default: false })
  isLoading!: boolean;
  @Prop({ required: false, default: true })
  canCreate!: boolean;
  @Prop({ required: false, default: "New Event" })
  newEventTitle!: string;
  @Prop({ required: false })
  showBackButton!: boolean;
  @Prop({ required: false, default: "Schedule" })
  calendarTitle!: string;

  private events: Array<CalendarEvent> = [];

  private pickerTouches: Record<"left" | "right", (param: TouchEvent) => void>;
  @Ref("vCalendar")
  private vCalendar!: VCalendar;
  constructor() {
    super();
    this.thisDay = dayjs();
    this.calendarType = "week";
    this.nowY = "200px";
    this.pickerTouches = {
      left: () => {
        this.moveNext();
        this.scrollDates();
      },
      right: () => {
        this.movePrevious();
        this.scrollDates();
      }
    };
    this.timers = [
      {
        name: "nowY",
        time: 60000,
        callback: this.setNowY,
        autostart: true,
        repeat: true
      }
    ];
  }

  @Watch("sourceEvents")
  changeEvents() {
    this.events = [...this.sourceEvents];
  }

  @Watch("createEvent")
  watchNewEvent(newVal: CalendarEvent | null, oldVal: CalendarEvent | null) {
    if (newVal === null) {
      this.$emit("newEvent", oldVal);
    } else {
      this.events = [...this.sourceEvents, newVal];
    }
  }

  @Watch("dragEvent")
  watchDraggedEvent(
    newVal: CalendarEvent | null,
    oldVal: CalendarEvent | null
  ) {
    if (newVal !== null) {
      // User clicked for the first time
      this.events = this.sourceEvents.map((e) => {
        if (e.id === newVal.id) {
          return newVal;
        }
        return e;
      });
    } else if (newVal === null && oldVal !== null) {
      // Drag Event has finished and we now have to decide whether to click or drag
      const originalEvent = this.sourceEvents.find((e) => e.id === oldVal.id);
      if (originalEvent) {
        if (
          Number(originalEvent.start) !== Number(oldVal.start) ||
          Number(originalEvent.end) !== Number(oldVal.end)
        ) {
          this.$emit("changeEvent", oldVal);
        } else {
          this.$emit("clickEvent", originalEvent);
        }
      }
    }
  }

  private setNowY() {
    const now = dayjs(new Date());
    this.nowY = 48 * now.hour() + (48 / 60) * now.minute() + "px";
  }

  async mounted(): Promise<void> {
    this.vCalendar.scrollToTime("09:00");
    this.scrollDates();
    await this.fetchMoreEvents();
  }

  @Watch("today")
  @Watch("calType")
  @Watch("$store.getters.currentUser")
  public fetchMoreEvents(): void {
    const options: GetEventsOptions = {
      type: this.calType,
      refDate: this.today
    };
    this.$emit("fetchMore", options);
  }

  public movePrevious(): void {
    this.thisDay = dayjs(this.today).subtract(1, this.calType);
  }

  public moveNext(): void {
    this.thisDay = dayjs(this.today).add(1, this.calType);
  }
  public setMonth(month: number): void {
    this.thisDay = this.thisDay.month(month);
    setTimeout(() => {
      this.scrollDates();
    }, 500);
  }

  public setYear(year: number): void {
    this.thisDay = this.thisDay.year(year);
  }

  public moveNextMonth(): void {
    this.setMonth(this.thisDay.month() + 1);
  }

  public movePreviousMonth(): void {
    this.setMonth(this.thisDay.month() - 1);
  }

  public moveToday(): void {
    this.thisDay = dayjs();
  }

  public get today(): string {
    return this.thisDay.format("YYYY-MM-DD");
  }

  public get showTodayBtn(): boolean {
    return this.today !== dayjs(new Date()).format("YYYY-MM-DD");
  }

  public get calType(): CalendarType {
    if (this.isDesktop) {
      return this.calendarType;
    } else {
      return "day";
    }
  }

  public get dateHeader(): string {
    return this.thisDay.format("MMMM, YYYY");
  }

  public get pickerMax(): string {
    return this.thisDay.endOf("month").format("YYYY-MM-DD");
  }

  public get pickerMin(): string {
    return this.thisDay.startOf("month").format("YYYY-MM-DD");
  }

  public get pickerValue(): string {
    return this.thisDay.format("YYYY-MM-DD");
  }

  public set pickerValue(val: string) {
    this.thisDay = dayjs(val);
  }

  private scrollDates(): void {
    const width =
      document.querySelector(
        ".mobile-picker.v-picker--full-width.theme--light > div > div > div.v-date-picker-table.v-date-picker-table--date.theme--light > table > thead th"
      )?.scrollWidth || 0;
    let weekOfMonth = this.thisDay.week() - this.thisDay.date(1).week();
    let steps = Number(this.thisDay.format("d"));
    if (steps === 0) {
      steps = 7;
    }
    // Just added the 2 pixels to align items
    const transition = width * (weekOfMonth * 7) + 2;
    setTimeout(() => {
      document
        .querySelector(
          ".mobile-picker.v-picker--full-width.theme--light > div > div > div.v-date-picker-table.v-date-picker-table--date.theme--light > table > tbody"
        )
        ?.scrollTo(transition, 0);
    }, 500);
  }

  // This is used to track a number of how long an event has been dragged for
  private dragTime: number | null = null;
  // This is the reference to the event that is being dragged
  private dragEvent: CalendarEvent | null = null;
  // This is the reference to a new event that user wants to create
  private createEvent: CalendarEvent | null = null;
  private createStart: number | null = null;
  private extendOriginal: number | null = null;
  private isExtending = false;

  private startDrag(e: MouseDownEventWrapper) {
    if (e.event && e.timed) {
      this.dragEvent = {
        ...e.event
      };
      this.dragTime = null;
      this.extendOriginal = null;
    }
  }
  private startTime(tms: MouseDownTimeEventWrapper) {
    const mouse = this.toTime(tms);

    if (this.dragEvent && this.dragTime === null) {
      this.dragTime = mouse - Number(this.dragEvent.start);
    } else {
      this.createStart = this.roundTime(mouse);
      if (this.canCreate) {
        this.createEvent = {
          name: this.newEventTitle,
          color: "primary",
          start: dayjs(new Date(this.createStart)).minute(0).toDate(),
          end: dayjs(new Date(this.createStart)).minute(60).toDate(),
          timed: true
        };
      }
    }
  }
  extendBottom(event: CalendarEvent) {
    if (event.canDrag) {
      this.dragEvent = {
        ...event
      };
      this.dragTime = Number(this.dragEvent.end) - Number(this.dragEvent.start);
      this.extendOriginal = null;
      this.isExtending = true;
    }
  }
  /**
   * This is the function running when the user moves across the time section of
   * the calendar, this is used by us to perform an action if the user is
   * interacting with an event
   */
  mouseMove(tms: MouseDownTimeEventWrapper) {
    const mouse = this.toTime(tms);
    if (this.dragEvent && this.dragTime !== null && this.dragEvent.canDrag) {
      const duration =
        Number(this.dragEvent.end) - Number(this.dragEvent.start);
      const newStartTime = mouse - this.dragTime;
      const newStart = this.roundTime(newStartTime);
      const newEnd = newStart + duration;
      if (this.isExtending) {
        // if (Number(this.dragEvent.end) - mouse <= 450000) {
        //   this.dragEvent.end = Number(this.dragEvent.end) + 900000;
        // } else if (Number(this.dragEvent.end) - mouse >= -450000) {
        //   this.dragEvent.end = Number(this.dragEvent.end) - 900000;
        // }
        this.dragEvent.end = mouse;
      } else {
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      }
    } else if (this.createEvent && this.createStart !== null) {
      const mouseRounded = this.roundTime(mouse, false);
      const min = Math.min(mouseRounded, this.createStart);
      const max = Math.max(mouseRounded, this.createStart);

      this.createEvent.start = min;
      this.createEvent.end = max;
    }
  }
  endDrag() {
    this.dragTime = null;
    this.dragEvent = null;
    this.createEvent = null;
    this.createStart = null;
    this.extendOriginal = null;
    this.isExtending = false;
  }
  cancelDrag() {
    if (this.createEvent) {
      if (this.extendOriginal) {
        this.createEvent.end = this.extendOriginal;
      } else {
        const i = this.events.indexOf(this.createEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }
    }

    this.createEvent = null;
    this.createStart = null;
    this.dragTime = null;
    this.dragEvent = null;
    this.isExtending = false;
  }
  roundTime(time: number, down = true) {
    const roundTo = 15; // minutes
    const roundDownTime = roundTo * 60 * 1000;

    if (down) {
      return time - (time % roundDownTime);
    } else {
      return time + (roundDownTime - (time % roundDownTime));
    }
  }
  toTime(tms: MouseDownTimeEventWrapper): number {
    return new Date(
      tms.year,
      tms.month - 1,
      tms.day,
      tms.hour,
      tms.minute
    ).getTime();
  }
}
